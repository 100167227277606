
import { computed, defineComponent, getCurrentInstance, ref } from 'vue'
import { useModalStore } from '@/store/stores/modal'
import useRouter from '@/hooks/useRouter'
export default defineComponent({
    setup() {
        const modalStore = useModalStore()
        const root = getCurrentInstance()!.proxy
        const router = useRouter(root)
        const isActive = computed({
            get: () => modalStore.active,
            set: v => modalStore.close(),
        })
        const redirect = (url: string) => {
            router.router.push(url)
            modalStore.close()
        }
        const customOk = () => {
            modalStore.buttons.okCallback();
            modalStore.close()
        }
        return { modalStore, isActive, redirect, customOk }
    },
})
