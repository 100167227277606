
import Vue from 'vue'
import TextField from '../../../../inputs/TextField.vue'
import { Project } from '../../../../../types/main'
import { checkModuleIsActive } from '../../../../../helpers/roles'
export default Vue.extend({
    components: { TextField },
    props: {
        value: { type: Object },
    },
    data: () => ({
        nameRules: [
            (v: any) => !!v || 'Обязательно',
            (v: any) => v?.trim().length >= 3 || `Меньше 3 символов`,
        ],
    }),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        tagsIsActive(): Boolean {
            return checkModuleIsActive(this.project.id, 'gcb2:tasklist:activationTags')
        },
    },
    methods: {
        formInput(e: any) {
            this.$emit('validation-updated', e)
        },
        clear() {
            ;(this.$refs as any).form.resetValidation()
        },
    },
    async created() {},
})
