<template>
    <v-form class="mb-6" @submit.prevent="() => {}" ref="form">
        <v-row class="ma-0">
            <div class="ml-4">
                <v-row class="ma-0" align="center">
                    <CustomActivatorSelect
                        class="service__input ml-0"
                        v-model="currentServiceId"
                        @input="addService"
                        :items="availableServiceList"
                        :multiple="false"
                    >
                        <template v-slot:activator="{ selected, _id }">
                            <BaseInputOutlined
                                label="Услуга"
                                hide-details
                                class="select-input"
                                append-icon="mdi-menu-down"
                                :id="_id"
                                :value="selected ? selected.name : ''"
                                readonly
                            >
                            </BaseInputOutlined>
                        </template>
                    </CustomActivatorSelect>
                </v-row>
                <div class="message-parts-editor mt-3">
                    <div class="service" v-for="service in includedServiceList" :key="service.id">
                        <BaseInputOutlined
                            style="width: 440px;"
                            label="Услуга"
                            hide-details
                            class="select-input service__input"
                            :id="service.id"
                            :value="service.name"
                            readonly
                        >
                        </BaseInputOutlined>
                        <AdvancedMessageEditor
                            class="mt-3"
                            style="width: 100%;"
                            :hide-view-state="true"
                            v-model="service.text"
                            label="Инструкции для услуги"
                            :disable-view-state="true"
                            :hide-vars-button="true"
                        />
                        <v-btn class="mt-2" icon @click="deleteService(service.id)">
                            <v-icon>mdi-delete-outline</v-icon></v-btn
                        >
                    </div>
                </div>
            </div>
        </v-row>
    </v-form>
</template>
<script>
import { defineComponent, ref, computed, watch, getCurrentInstance, onMounted } from 'vue'
import { getServiceList } from '@api/chisai/GCB2'
import useRouter from '../../../../../hooks/useRouter'
import { v4 as uuidv4 } from 'uuid'
import * as _ from 'lodash'
import BaseInputOutlined from '../../../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../../../inputs/CustomActivatorSelect.vue'
import { onBeforeMount } from 'vue'
import AdvancedMessageEditor from '@root/src/components/ui/AdvancedMessageEditor.vue'

export default defineComponent({
    name: 'InstructionsStep',
    components: { BaseInputOutlined, CustomActivatorSelect, AdvancedMessageEditor },
    props: {
        value: { type: Object, required: true },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance().proxy
        const { route } = useRouter(root)
        const project = computed(() => root.$store.getters.projectById(route.value.params.id))
        const triggerValue = ref(props.value)

        const currentServiceId = ref()
        const selectedService = computed(() => {
            return serviceList.value.find(el => el.id === currentServiceId.value)
        })
        const serviceMap = ref({})
        const serviceList = ref([])
        const availableServiceList = computed(() => {
            const includedServiceIds = new Set(includedServiceList.value?.map(ex => ex.id))

            return serviceList.value.map(el => ({
                ...el,
                disabled: includedServiceIds.has(el.id),
            }))
        })
        const includedServiceList = ref([])
        const activeMessageVarId = ref('{name}')
        const clear = () => {
            includedServiceList.value = []
        }
        const instructionsList = ref(props.value.instructions)

        const addService = () => {
            includedServiceList.value?.push({
                id: selectedService.value.id,
                name: selectedService.value.name,
                text: '',
            })
            currentServiceId.value = ''
        }
        const deleteService = id =>
            (includedServiceList.value = includedServiceList.value.filter(el => el.id !== id))
        const updateTrigger = () => {
            triggerValue.value.instructions = includedServiceList.value?.map(el => {
                return { id: el.id, text: el.text }
            })
            emit('update-trigger', triggerValue.value)
        }
        watch(
            () => includedServiceList.value,
            v => {
                updateTrigger()
            },
            { deep: true, immediate: true }
        )
        watch(
            () => serviceMap.value,
            v => {
                includedServiceList.value = includedServiceList.value?.map(service => {
                    if (serviceMap.value[service.id]) {
                        service.name = serviceMap.value[service.id]
                    }
                    return service
                })
            },
            { deep: true, immediate: true }
        )
        onBeforeMount(async () => {
            const res = await getServiceList({ projectId: project.value.id }).then(d => d.data)
            serviceList.value = res
            serviceMap.value = serviceList.value?.reduce((map, service) => {
                map[service.id] = service.name
                return map
            }, {})
        })
        onMounted(() => {
            if (props.value.instructions) {
                includedServiceList.value = instructionsList.value || []
            }
        })

        return {
            clear,
            activeMessageVarId,
            serviceList,
            includedServiceList,
            addService,
            deleteService,
            currentServiceId,
            selectedService,
            availableServiceList,
            serviceMap,
            instructionsList,
            updateTrigger,
        }
    },
})
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
::v-deep .v-input__append-outer
    margin-top: 9px
::v-deep .v-input__prepend-inner
    margin-top: 9px
.message-parts-slider-title
    color: $gray
    font-size: 12px
    line-height: 16px
    font-weight: $font-weight-medium
    letter-spacing: 1.07px
.slider-part
    display: flex
    align-items: center
    cursor: pointer
    width: 249px
    height: 40px
    border-left: 4px solid $gray-2
    &.active
        border-left: 4px solid $main
    &.disabled
        opacity: 0.5
        pointer-events: none
.slider-part-text
    margin-left: 8px
    color: $gray
    &.active
        color: $text-color
.message-parts-editor
    position: relative
    width: 100%
    min-height: 350px
    max-height: 500px
    border: 1px solid $light-gray-4
    border-radius: 4px
    overflow-y: visible
    overflow-x: hidden
    &::after
        display: block
        content: ''
        position: sticky
        bottom: -1px
        left: 0
        right: 0
        height: 36px
        width: 930px
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffffc9)
        margin-top: -24px
        pointer-events: none
*
    scrollbar-width: auto
    scrollbar-color: $light-gray #ffffff

*::-webkit-scrollbar
    width: 14px
*::-webkit-scrollbar-track
    background: #ffffff

*::-webkit-scrollbar-thumb
    background-color: $light-gray
    border-radius: 8px
    border: 3px solid #ffffff
*::-webkit-scrollbar-thumb:hover
    background-color: $light-gray-2


.service
    display: flex
    align-items: flex-start
    flex-direction: row
    width:100%
    &__input
        margin: 12px
        flex-grow: 0.5
    &:last-child
        margin-bottom: 12px
</style>
