<template>
    <div class="auth">
        <div class="auth">
            <h2>Для интеграции вам необходимо запросить в техподдержке Архимеда следующие данные:<br/>Api id; Api secret</h2>
            <div class="form-block pa-10">
                <v-row class="mx-0 mb-4" justify="center" align="center">
                    <h2>{{ currentAddingConnector.name }}</h2>
                </v-row>
                <v-form v-model="valid">
                    <v-row class="ma-0">
                        <TextField
                          type="password"
                          color="main"
                          filled
                          v-model="apiId"
                          label="Api id"
                          required
                        ></TextField>
                        <TextField
                          type="password"
                          color="main"
                          filled
                          v-model="apiSecret"
                          label="Api secret"
                          required
                        ></TextField>
                    </v-row>
                    <p v-if="validLogin === false" class="error--text">Не верные данные</p>
                    <div class="btn-wrap">
                        <v-btn
                          color="white"
                          class="black--text to-crm-select-btn"
                          @click="$parent.$emit('goBackToCrm')"
                        >Назад к выбору CRM</v-btn>
                        <v-btn
                          color="main"
                          class="white--text"
                          @click="login"
                          :disabled="btnDisabled"
                          :loading="loading"
                        >Войти</v-btn>
                    </div>
                </v-form>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import cfg from '../../../../config'
import { login } from '@api/connectors/archimed'
import { CONNECTOR_TYPES } from '@/vars/general'
export default {
    components: { TextField, HintIcon },
    data: () => ({
        validLogin: null,
        valid: false,
        apiId: '',
        apiSecret: '',
        loading: false,
        infoStep: true,
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        btnDisabled() {
            if (!this.apiId || !this.apiSecret) {
                return true
            }
            return false
        },
    },
    methods: {
        async login() {
            this.loading = true
            const credentials = {
                apiId: this.apiId,
                apiSecret: this.apiSecret,
            }
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.archimed,
            })
            const isLogin = await login(connector, credentials)

            if (isLogin) {
                this.$store.commit('project/setLoggedAddingConnector', connector)
                this.$parent.$emit('login')
            }
            this.validLogin = isLogin
            this.loading = false
        },
        async toggleinfo() {
            this.infoStep = !this.infoStep
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    gap: 12px
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    max-width: 460px
.btn-wrap
    margin-top: 12px
    display: flex
    gap: 20px
</style>
