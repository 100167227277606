
import Vue, { PropType } from 'vue'
import { changeBanServiceList, getBanServiceList } from '../../../../api/chisai/GCB2'
import { Project } from '../../../../types/main'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import _ from 'lodash'
import tasklistActions from '@root/src/components/chisai/GCB2/taskList/actions'
import CustomActivatorSelect from '@root/src/components/inputs/CustomActivatorSelect.vue'
import TextField from '@root/src/components/inputs/TextField.vue'
export default Vue.extend({
    components: { SpinnerLoader, CustomActivatorSelect, TextField },
    props: {
        value: { type: Boolean as PropType<boolean> },
    },
    data: () => ({
        serviceList: [] as any[],
        dataLoading: true as boolean,
        actionLoading: false as boolean,
        startSelected: [] as any[],
        selected: [] as any[],
    }),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        diff(): { added: any[]; deleted: any[] } {
            return {
                added: _.difference(this.selected, this.startSelected),
                deleted: _.difference(this.startSelected, this.selected),
            }
        },
        selectedChanged(): boolean {
            return Boolean(this.diff.added.length || this.diff.deleted.length)
        },
        isDialog: {
            get(): boolean {
                return this.value
            },
            set(value: boolean): void {
                this.$emit('input', value)
            },
        },
        selectedIds: {
            get(): any[] {
                return this.selected.map(el => el.id)
            },
            set(value: string[]): void {
                const currendAddedId = _.difference(value, this.selectedIds)
                const selectedCopy = [...this.selected]
                if (currendAddedId.length !== 0) {
                    const addEl: any = this.serviceList.find(el => el.id === currendAddedId[0])
                    selectedCopy.push(addEl)
                    this.selected = [...selectedCopy]
                } else {
                    const currendDelId = _.difference(this.selectedIds, value)
                    const selectedNew = selectedCopy.filter(el => el.id !== currendDelId[0])
                    this.selected = [...selectedNew]
                }
                
                
            },
        },
        textFieldString(): string {
            if (this.selected && this.selected.length === 1) {
                return `${this.selected[0]?.title}`
            } else if (this.selected && this.selected.length > 1) {
                return `${this.selected[0]?.title} (+${this.selected.length - 1})`
            } else {
                return null
            }
            
        },
        contentClass(): string {
            return this.selectedChanged ? 'custom-modal_expanded' : 'custom-modal'
        }
    },
    watch: {
        async isDialog() {
            if (this.isDialog) {
                this.setInitialData()
            }
        },
        selected: {
            deep: true,
            handler() {
                for (let el in this.selected) {

                }
            }
        },
    },
    methods: {
        async setInitialData() {
            this.dataLoading = true
            this.serviceList = (await getBanServiceList({ projectId: this.project.id })).data || []
            this.selected = this.serviceList.filter(el => el.banned)
            this.startSelected = [...this.selected]
            this.dataLoading = false
        },
        async changeBanServiceList() {
            this.actionLoading = true

            await tasklistActions.dontRemindService(this, this.project.id, this.diff)
            this.actionLoading = false
            this.isDialog = false
        },
    },
})
