import { getInstance } from '@/auth/authWrapper'
import cfg from '../../config'
import { ApiWrapper, ProjectType } from '../types/main'
import { getError } from '../utils'
import { apiCall, http } from './http'
export async function createPendingConnectorRecordYc({
    userName,
    userEmail,
    projectName,
    projectId,
    connectorId,
}: any) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/projects/createPendingConnectorRecord`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            userName,
            userEmail,
            projectId,
            projectName,
            connectorId,
        }),
    }
    const res = await fetch(url, options)
    return res
}

export async function getSettingsList(projectId: string): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/projects/settingsList`)
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getCurrentSettings(projectId: string): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/projects/currentSettings`)
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function saveSettings({
    projectId,
    settings,
}: {
    projectId: string
    settings: { string: any }
}): Promise<ApiWrapper<any | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/projects/saveProjectSettings`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            settings,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function fetchConnector(connectorId: string): Promise<ApiWrapper<any | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/projects/connector`)
    const params: any = { connectorId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function saveRoleSettings({ projectId, settings }: any): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/projects/settings/roles`)
    const options = {
        method: 'PUT',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            projectId,
            settings,
        }),
    }

    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function getType({ projectId }: { projectId: string }) {
    const url = `projects/type`
    const res = apiCall<{ type: ProjectType }>(() =>
        http.get(url.toString(), { searchParams: { projectId } })
    )
    return res
}
export async function getNetworkProjects({ projectId }: { projectId: string }) {
    const url = `projects/networkProjects`
    const res = apiCall<{ id: string; name: string; isMain: boolean }[]>(() =>
        http.get(url.toString(), { searchParams: { projectId } })
    )
    return res
}

interface InitFromMarketPlaceDikidiParams {
    type: string
    encodedUserData: string
    salonId: string
    salonIds?: string[]
    enableSms?: boolean
    userSign: string
}
interface InitFromMarketPlaceYClientsParams {
    type: string
    encodedUserData: string
    companyId: string
    token: string
    userSign: string
}
export async function initFromMarketPlace(
    params: InitFromMarketPlaceDikidiParams | InitFromMarketPlaceYClientsParams
) {
    const url = `projects/initFromMarketPlace`
    const res = apiCall<{ type: ProjectType }>(() => http.post(url, { json: params }))
    return res
}
export async function getPendingConnectorsByProject({
                                       projectId,
                                   }: {
    projectId: string
}): Promise<ApiWrapper<any | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/projects/getPendingConnectors`)
    const params: any = { projectId }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    if (res.status >= 400) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
