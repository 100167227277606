
import Vue from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import { Connector, Project } from '../../types/main'
import { ChangeBotTriggerMode, EditTriggerStep } from '@/types/GCB2'
import { CHANGE_BOT_TRIGGER_TYPES } from '../../vars/GCB2'
import _, { isNil } from 'lodash'
import { replaceElementAtIndex } from '../../utils'
import TextStep from '@/components/chisai/GCB2/bot/EditSteps/TextStep.vue'
import NameStep from '@/components/chisai/GCB2/bot/EditSteps/NameStep.vue'
import ReactionsAndAnswersStep from '@/components/chisai/GCB2/bot/EditSteps/ReactionsAndAnswersStep.vue'
import SpinnerLoader from '../../components/SpinnerLoader.vue'
import { CONNECTOR_TYPES } from '../../vars/general'
import api from '../../api/api'
import { canActivateTrigger } from '../../helpers/whatsapp'
import MediaStep from '../../components/chisai/GCB2/bot/EditSteps/MediaStep.vue'
import SendSettingsStep from '../../components/chisai/GCB2/bot/EditSteps/SendSettingsStep.vue'
import BanlistStep from '../../components/chisai/GCB2/bot/EditSteps/BanlistStep.vue'
import CustomReactionsStep from '../../components/chisai/GCB2/bot/EditSteps/CustomReactionsStep.vue'
import { isValidPhone } from '@root/src/helpers/validators/phoneValidator'
import PersonalStep from '../../components/chisai/GCB2/bot/EditSteps/PersonalStep.vue'
import TriggerTypeStep from '@root/src/components/chisai/GCB2/bot/EditSteps/TriggerTypeStep.vue'
export default Vue.extend({
    components: {
        Breadcrumbs,
        SpinnerLoader,
        TextStep,
        SendSettingsStep,
    },

    data: () => ({
        CHANGE_BOT_TRIGGER_TYPES,
        loading: true as boolean,
        stepsIndexes: [] as number[] | number,
        activeStepsIndexes: [] as number[] | number,
        copyLoading: false as boolean,
        triggerIdFromQuery: null as any,
        triggers: {} as any,
        customTriggers: {} as any,
        allTriggers: {} as any,
        app: {} as any,
        trigger: {} as any,
        createOrEditLoading: false as boolean,
        mode: null as ChangeBotTriggerMode | null,
        editableSteps: null as EditTriggerStep[],
        editableActiveSteps: null as EditTriggerStep[],
        panelsKey: 0 as number,
    }),
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler() {
                this.mode = this.$route.query.mode as ChangeBotTriggerMode
                this.triggerIdFromQuery = this.$route.query.id
            },
        },
        stepsIndexes(): void {
            if (this.mode === CHANGE_BOT_TRIGGER_TYPES.create) {
                this.activeStepsIndexes = Array.isArray(this.stepsIndexes)
                    ? [...this.stepsIndexes]
                    : [this.stepsIndexes].filter(el => !isNil(el))
            }
        },
        event(v): void {
            if (this.triggerIdFromQuery.startsWith('custom_')) {
                const updatedAvailableSteps = this.steps.filter(el =>
                    el.showCondition ? el.showCondition(this.project) : true
                )
                if (this.mode !== CHANGE_BOT_TRIGGER_TYPES.create) {
                    this.availableSteps = updatedAvailableSteps
                    this.panelsKey++
                } else {
                    const updatedAvailableStepsWithSaves = updatedAvailableSteps.map(el => {
                        const isStep = this.availableSteps.find(step => el.id === step.id)
                        const savedExp = () => {
                            if (el.id === 8) {
                                if (this.savedSteps.length >= 5) {
                                    return true
                                }
                                return false
                            }
                            if (isStep) {
                                return isStep?.saved
                            } else {
                                return false
                            }
                        }
                        return {
                            id: el.id,
                            title: el.title,
                            contentComponent: el.contentComponent,
                            ref: el.ref,
                            saved: savedExp(),
                            saveBtnDisabled: isStep ? isStep.saveBtnDisabled : false,
                            showCondition: el.showCondition,
                            qustomRequired: el.qustomRequired ? el.qustomRequired : false,
                        }
                    })
                    this.availableSteps = updatedAvailableStepsWithSaves
                    this.panelsKey++
                }
            }
        },
    },
    computed: {
        steps: {
            get: function(): EditTriggerStep[] {
                return (
                    this.editableSteps ??
                    ([
                        {
                            id: 1,
                            title: 'Название и Описание',
                            contentComponent: NameStep,
                            ref: 'setting-1',
                            saved: false,
                            saveBtnDisabled: true,
                            showCondition: project => this.triggerIdFromQuery.startsWith('custom_'),
                            qustomRequired: true,
                        },
                        {
                            id: 2,
                            title: 'Тип триггера',
                            contentComponent: TriggerTypeStep,
                            ref: 'setting-2',
                            saved: false,
                            saveBtnDisabled: true,
                            showCondition: project => this.triggerIdFromQuery.startsWith('custom_'),
                            qustomRequired: true,
                        },
                        {
                            id: 3,
                            title: 'Текст',
                            contentComponent: TextStep,
                            ref: 'setting-3',
                            saved: false,
                            saveBtnDisabled: true,
                            qustomRequired: true,
                        },

                        {
                            id: 4,
                            title: 'Ответы',
                            contentComponent: ReactionsAndAnswersStep,
                            ref: 'setting-4',
                            saved: false,
                            saveBtnDisabled: true,
                            showCondition: project => this.triggerIdFromQuery === 'afterVisit',
                        },
                        {
                            id: 5,
                            title: 'Ответы',
                            contentComponent: CustomReactionsStep,
                            ref: 'setting-5',
                            saved: false,
                            saveBtnDisabled: true,
                            showCondition: project => this.triggerIdFromQuery === 'notificBeforeRecord1Day',
                        },
                        {
                            id: 6,
                            title: 'Персональные настройки',
                            contentComponent: PersonalStep,
                            ref: 'setting-6',
                            saved: false,
                            saveBtnDisabled: false,
                            showCondition: project => this.triggerIdFromQuery.startsWith('custom_'),
                        },
                        {
                            id: 7,
                            title: 'Медиафайлы',
                            contentComponent: MediaStep,
                            ref: 'setting-9',
                            saved: false,
                            saveBtnDisabled: false,
                        },
                        {
                            id: 8,
                            title: 'Настройки отправки',
                            contentComponent: SendSettingsStep,
                            ref: 'setting-9',
                            saved: false,
                            saveBtnDisabled: false,
                            showCondition: project =>
                                [
                                    'afterVisit',
                                    'notificBeforeRecord1Day',
                                    'notificBeforeRecord2Hours',
                                    'afterRecordClientCome',
                                ].includes(this.triggerIdFromQuery) ||
                                ['notificationBefore1Day', 'notificationBefore2Hours'].includes(this.event),
                        },
                        {
                            id: 9,
                            title: 'Черный список',
                            contentComponent: BanlistStep,
                            ref: 'setting-10',
                            saved: false,
                            saveBtnDisabled: false,
                        },
                    ] as EditTriggerStep[])
                )
            },
            set: function(newValue: EditTriggerStep[]) {
                this.editableSteps = newValue
                return newValue
            },
        },
        event(): string {
            return this.trigger.event || null
        },
        title(): string {
            return this.mode === CHANGE_BOT_TRIGGER_TYPES.create ? 'Новый триггер' : 'Редактирование'
        },
        expandDisabled(): boolean {
            return this.mode === CHANGE_BOT_TRIGGER_TYPES.edit
        },
        panelsIsMultiplie(): boolean {
            return this.mode === CHANGE_BOT_TRIGGER_TYPES.edit
        },
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        botConnector(): Connector {
            return this.project.connectors.find(
                el => el.connectorType === CONNECTOR_TYPES.whatsappBasis
            ) as Connector
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'Кого из клиентов стоит позвать',
                    to: `/project/${this.project.id}/GCB2?section=bot`,
                },
                {
                    text: 'Бот',
                    to: '',
                    disabled: true,
                },
            ]
        },
        activeSwitchLabel(): string {
            if (
                this.triggerIdFromQuery.startsWith('custom_') &&
                this.mode === CHANGE_BOT_TRIGGER_TYPES.create
            ) {
                return 'Начать отправку сообщений?'
            } else if (this.trigger.status === 'on') {
                return 'Деактивировать'
            } else {
                return 'Активировать'
            }
        },
        activeSwitchDisabled(): boolean {
            return !this.canActivateTrigger(this.triggerIdFromQuery, this.activeTriggersIds).canActivate
        },
        activationIsActiveTooltipText(): string | undefined {
            return this.canActivateTrigger(this.triggerIdFromQuery, this.activeTriggersIds).errorText
        },

        savedSteps(): any[] {
            return this.availableSteps.filter(el => el.saved)
        },
        availableStepsIds(): number[] {
            if (this.mode === CHANGE_BOT_TRIGGER_TYPES.create) {
                const nextStepIndex =
                    this.availableSteps.findIndex(
                        el => el.id === this.savedSteps[this.savedSteps.length - 1]?.id
                    ) + 1
                const nextStep = this.availableSteps[nextStepIndex]
                return [...this.savedSteps.map(el => el.id), nextStep?.id]
            } else {
                return this.availableSteps.map(el => el.id)
            }
        },
        availableSteps: {
            get: function(): EditTriggerStep[] {
                return (
                    this.editableActiveSteps ||
                    this.steps.filter(el => (el.showCondition ? el.showCondition(this.project) : true))
                )
            },
            set: function(newValue: EditTriggerStep[]) {
                this.editableActiveSteps = newValue
                return newValue
            },
        },
        saveButtonDisabled(): boolean {
            if (!this.triggerIdFromQuery.startsWith('custom_')) {
                return (
                    this.trigger.banOnClient.some((el: string) => !isValidPhone(el)) ||
                    this.app.banOnClient.some((el: string) => !isValidPhone(el))
                )
            } else {
                return (
                    this.trigger.banOnClient.some((el: string) => !isValidPhone(el)) ||
                    this.app?.banOnClient?.some((el: string) => !isValidPhone(el)) ||
                    !this.trigger.name ||
                    this.trigger.name.trim().length < 3 ||
                    !this.trigger.description ||
                    this.trigger.description.trim().length < 3 ||
                    !this.trigger.text ||
                    this.trigger.text.trim().length < 3 ||
                    (this.trigger.text &&
                        this.trigger.text.includes('{информация-о-записях}') &&
                        (!this.trigger.template || this.trigger.template.trim().length < 3)) ||
                        (
                            (this.trigger.event && this.trigger.event === 'notificationBefore2Hours' && this.trigger.isCustomNotificationTime && !this.trigger.notificationTime) ||
                            (this.trigger.event && this.trigger.event === 'notificationBefore2Hours' && this.trigger.isCustomNotificationTime && this.trigger.notificationTime > 120) ||
                            (this.trigger.event && this.trigger.event === 'notificationBefore2Hours' && this.trigger.isCustomNotificationTime && this.trigger.notificationTime < 1)
                        )
                )
            }
        },
        activeTriggersIds(): string[] {
            return Object.keys(this.triggers)
                .map(id => ({ id, ...this.triggers[id as any] }))
                .filter(trigger => trigger.status === 'on')
                .map(trigger => trigger.id)
        },
    },
    methods: {
        canActivateTrigger,
        async init() {
            this.loading = true
            if (!this.mode) {
                this.mode = CHANGE_BOT_TRIGGER_TYPES.create as ChangeBotTriggerMode
                this.$router.push({
                    path: this.$router.currentRoute.path,
                    query: Object.assign({}, this.$router.currentRoute.query, {
                        mode: CHANGE_BOT_TRIGGER_TYPES.create,
                    }),
                })
            }
            if (this.mode === CHANGE_BOT_TRIGGER_TYPES.create) {
                this.stepsIndexes = 0
                if (this.triggerIdFromQuery.startsWith('custom_')) {
                    this.trigger.status = sessionStorage.getItem('triggerStatusToCreate') || 'off'
                }
            }
            if (this.mode === CHANGE_BOT_TRIGGER_TYPES.edit && this.triggerIdFromQuery) {
                this.app = await api.networkFixer
                    .getApp({ connectorId: this.botConnector.connectorId })
                    .then(res => res.data!.data)
                this.triggers = this.app.triggers
                this.customTriggers = this.app.customTriggers?.reduce((acc, el) => {
                    acc[el.triggerId] = el
                    return acc
                }, {})
                this.allTriggers = Object.assign({}, this.triggers, this.customTriggers)
                this.trigger = this.allTriggers[this.triggerIdFromQuery]
                this.stepsIndexes = this.steps.map((el, i) => i)
            }
            if (!this.trigger.answersStatus) {
                this.trigger.answersStatus = 'on'
            }
            if (this.triggerIdFromQuery.startsWith('custom_')) {
                if (!this.trigger.customDelay) {
                    this.trigger.customDelay = '00:00:00'
                }
                if (!this.trigger.sendToStaff) {
                    this.trigger.sendToStaff = false
                }
            }
            if (!this.trigger.visitsCountCondition && this.triggerIdFromQuery.startsWith('custom_')) {
                this.trigger.visitsCountCondition = { min: '', max: '' }
            }
            if (!this.trigger.autoLeaveScoreYc) {
                this.trigger.autoLeaveScoreYc = false
            }
            if (!this.trigger.media) {
                this.trigger.media = {}
            }
            if (
                !this.trigger.banOnClient ||
                (this.trigger.banOnClient.length === 1 && this.trigger.banOnClient[0] === '')
            ) {
                this.trigger.banOnClient = []
            }
            if (
                !this.app.banOnClient ||
                (this.app.banOnClient.length === 1 && this.app.banOnClient[0] === '')
            ) {
                this.app.banOnClient = []
            }
            if (!this.trigger.answersList && this.triggerIdFromQuery === 'afterVisit') {
                this.trigger.answersList = {
                    1: ['1', '1-', '1+', '0', '0-', '0+', '1!', '0!', '1.'],
                    2: ['2', '2+', '2-', '2', '2.'],
                    3: ['3', '3+', '3-', '3'!, '3.'],
                    4: ['4', '4+', '4', '4!', '4.'],
                    5: ['5', '5+', '5-', '5!', '5.', '6', '10', '10!', '555555', '5!!'],
                }
            }
            if (!this.trigger.answersList && this.triggerIdFromQuery === 'notificBeforeRecord1Day') {
                this.trigger.answersList = {
                    1: ['1', 'ок', 'подтверждаю', 'да', 'хорошо', 'буду'],
                    2: ['2', 'отмените', 'не приду'],
                }
            }
            this.loading = false
        },
        saveStep(step: EditTriggerStep, index: number) {
            this.availableSteps = replaceElementAtIndex(
                this.availableSteps,
                index,
                Object.assign({}, step, { saved: true })
            )
            const nextStepIndex = index + 1
            this.stepsIndexes = nextStepIndex
            this.activeStepsIndexes = [nextStepIndex]
        },
        clear(step: EditTriggerStep) {
            switch (step.id) {
                case 1:
                    this.trigger.name = ''
                    this.trigger.description = ''
                    break
                case 2:
                    this.trigger.event = null
                    this.trigger.customDelay = '00:00:00'
                    break
                case 3:
                    this.trigger.text = ''
                    this.trigger.template = ''
                    break
                case 4:
                    break
                case 5:
                    break
                case 6:
                    break
                case 7:
                    this.trigger.media = {}
                    break
                case 8:
                    this.trigger.staticSendDate = []
                    this.trigger.sendBefore = false
                    this.trigger.isCustomNotificationTime = false
                    break
                case 9:
                    this.trigger.banOnClient = []
                    this.trigger.banOnStaff = []
                    break
                default:
                    break
            }
        },
        updateSaveButton(e: boolean, step: any, index: number) {
            this.availableSteps = replaceElementAtIndex(
                this.availableSteps,
                index,
                Object.assign({}, step, { saveBtnDisabled: !e })
            )
        },

        goToTriggersOverview() {
            this.$router.push(`/project/${this.project.id}/GCB2?section=bot`)
        },
        async createTrigger() {
            this.createOrEditLoading = true
            const statusFromStore = sessionStorage.getItem('triggerStatusToCreate')
            const cloneTrigger = _.cloneDeep(this.trigger)
            if (statusFromStore) {
                sessionStorage.removeItem('triggerStatusToCreate')
            }
            if (
                this.trigger.event !== 'clientComeAndPaid' &&
                this.trigger.event !== 'notificationBefore1Day'
            ) {
                delete cloneTrigger.answers
                delete cloneTrigger.answersList
                delete cloneTrigger.reactions
            }
            if (this.trigger.event !== 'notificationBefore2Hours') {
                delete cloneTrigger.isCustomNotificationTime
                delete cloneTrigger.notificationTime
                delete cloneTrigger.sendBefore
            }
            if (this.trigger.event !== 'notificationBefore1Day') {
                delete cloneTrigger.staticSendDate
            }
            if (
                this.trigger.event === 'notificationBefore1Day' ||
                this.trigger.event === 'notificationBefore2Hours'
            ) {
                delete cloneTrigger.customDelay
            }
            if (!this.trigger.staffIds || this.trigger.staffIds.length === 0) {
                delete cloneTrigger.staffIds
            }
            if (!this.trigger.instructions || this.trigger.instructions.length === 0) {
                delete cloneTrigger.instructions
            }
            if (!this.trigger.delay) {
                delete cloneTrigger.delay
            }
            if (!this.trigger.visitsCountCondition.min && !this.trigger.visitsCountCondition.max) {
                delete cloneTrigger.visitsCountCondition
            } else if (cloneTrigger.visitsCountCondition.min > cloneTrigger.visitsCountCondition.max) {
                ;[cloneTrigger.visitsCountCondition.min, cloneTrigger.visitsCountCondition.max] = [
                    cloneTrigger.visitsCountCondition.max,
                    cloneTrigger.visitsCountCondition.min,
                ]
            }
            this.trigger = cloneTrigger
            this.app = await api.networkFixer
                .getApp({ connectorId: this.botConnector.connectorId })
                .then(res => res.data!.data)
            this.customTriggers = this.app.customTriggers?.reduce((acc, el) => {
                acc[el.triggerId] = el
                return acc
            }, {})
            if (!this.trigger.text.includes('{информация-о-записях}')) {
                this.trigger.template = ''
            }
            const customTriggersToSend = this.customTriggers ? Object.values(this.customTriggers) : []
            const { data, error } = await api.networkFixer.updateApp({
                connectorId: this.botConnector.connectorId,
                data: {
                    banOnStaff: this.app.banOnStaff,
                    banOnClient: this.app.banOnClient,
                    customTriggers: [
                        ...customTriggersToSend,
                        { ...this.trigger, triggerId: this.triggerIdFromQuery },
                    ],
                },
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Произошла ошибка при создании активации')
                this.createOrEditLoading = false
                return
            }
            this.createOrEditLoading = false
            this.goToTriggersOverview()
        },
        async updateTrigger() {
            this.createOrEditLoading = true
            let data = null
            let error = null
            const customTriggersToSend: any[] = this.customTriggers ? Object.values(this.customTriggers) : []
            const filteredCustomTriggersToSend = customTriggersToSend.filter(
                el => el.triggerId !== this.trigger.triggerId
            )
            if (
                this.triggerIdFromQuery.startsWith('custom_') &&
                !this.trigger.text.includes('{информация-о-записях}')
            ) {
                this.trigger.template = ''
            }

            if (this.triggerIdFromQuery.includes('custom_')) {
                const cloneTrigger = _.cloneDeep(this.trigger)
                if (
                    this.trigger.event !== 'clientComeAndPaid' &&
                    this.trigger.event !== 'notificationBefore1Day'
                ) {
                    delete cloneTrigger.answers
                    delete cloneTrigger.answersList
                    delete cloneTrigger.reactions
                }
                if (this.trigger.event !== 'notificationBefore2Hours') {
                    delete cloneTrigger.isCustomNotificationTime
                    delete cloneTrigger.notificationTime
                    delete cloneTrigger.sendBefore
                }
                if (this.trigger.event !== 'notificationBefore1Day') {
                    delete cloneTrigger.staticSendDate
                }
                if (
                    this.trigger.event === 'notificationBefore1Day' ||
                    this.trigger.event === 'notificationBefore2Hours'
                ) {
                    delete cloneTrigger.customDelay
                }
                if (!this.trigger.staffIds || this.trigger.staffIds.length === 0) {
                    delete cloneTrigger.staffIds
                }
                if (!this.trigger.instructions || this.trigger.instructions.length === 0) {
                    delete cloneTrigger.instructions
                }
                if (!this.trigger.delay) {
                    delete cloneTrigger.delay
                }
                if (!this.trigger.visitsCountCondition.min && !this.trigger.visitsCountCondition.max) {
                    delete cloneTrigger.visitsCountCondition
                } else if (cloneTrigger.visitsCountCondition.min > cloneTrigger.visitsCountCondition.max) {
                    ;[cloneTrigger.visitsCountCondition.min, cloneTrigger.visitsCountCondition.max] = [
                        cloneTrigger.visitsCountCondition.max,
                        cloneTrigger.visitsCountCondition.min,
                    ]
                }
                this.trigger = cloneTrigger

                const { data: d, error: e } = await api.networkFixer.updateApp({
                    connectorId: this.botConnector.connectorId,
                    data: {
                        banOnStaff: this.app.banOnStaff,
                        banOnClient: this.app.banOnClient,
                        customTriggers: [...filteredCustomTriggersToSend, this.trigger],
                    },
                })
                data = d
                error = e
            } else {
                const { data: d, error: e } = await api.networkFixer.updateApp({
                    connectorId: this.botConnector.connectorId,
                    data: {
                        banOnStaff: this.app.banOnStaff,
                        banOnClient: this.app.banOnClient,
                        triggers: {
                            [this.triggerIdFromQuery]: this.trigger,
                        },
                    },
                })
                data = d
                error = e
            }

            if (error) {
                this.$store.dispatch('callNotify', 'Произошла ошибка при создании активации')
                this.createOrEditLoading = false
                return
            }
            this.createOrEditLoading = false
            this.goToTriggersOverview()
        },
        updateApp(v: any) {
            this.app = v
        },
        updateTriggerfromChild(val) {
            this.trigger = val
        },
        insertTriggerFromCopy() {
            const triggerCopy = JSON.parse(sessionStorage.getItem('triggerToCopy'))
            if (triggerCopy) {
                this.updateTriggerfromChild(triggerCopy)
                sessionStorage.removeItem('triggerToCopy')
                this.trigger.triggerId = this.triggerIdFromQuery
            } else {
                this.$store.dispatch('callNotify', 'Нужно сначала скопировать ранее созданный триггер')
            }
        },
    },

    async created() {
        await this.init()
    },
    mounted() {},
})
