
import Vue, { ref } from 'vue'
import { Project } from '@/types/main'
import { checkRole } from '../../../../../helpers/roles'
export default Vue.extend({
    props: {
        cardData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        isMessageShow: ref(false),
    }),
    computed: {
        toogleIsRunningText(): string {
            return this.cardData.isRunning ? 'Деактивировать' : 'Активировать'
        },

        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
    },
    methods: {
        checkRole,
        goToSettings() {
            this.$router.push(`${this.$router.currentRoute.path}/botTrigger?mode=edit&id=${this.cardData.id}`)
        },
        changeIsRunning() {
            this.$emit('change-is-running', {
                id: this.cardData.id,
                value: !this.cardData.isRunning,
            })
        },
        setMessageShow(status) {
            this.isMessageShow = status
        },
        copyTrigger() {
            sessionStorage.setItem('triggerToCopy', JSON.stringify(this.cardData.triggerData))
            this.setMessageShow(true)
            setTimeout(() => {
                this.setMessageShow(false)
            }, 1000)
        },
        deleteCustomTrigger() {
            this.$emit('delete-trigger', this.cardData.id)
        },
    },
})
