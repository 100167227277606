
import BaseInputOutlined from '@root/src/components/inputs/BaseInputOutlined.vue'
import useRouter from '@root/src/hooks/useRouter'
import { watch } from 'vue'
import { computed, onMounted, reactive, ref } from 'vue'
import { defineComponent, getCurrentInstance } from 'vue'

export default defineComponent({
    name: 'VisitsStep',
    components: { BaseInputOutlined },
    props: {
        value: { type: Object, required: true },
    },
    data: () => ({
        numRule: v => {
            if (v === null || v === '') return true; // Если поле пустое
            if (!isNaN(Number(v)) && v >= 0 && Number.isInteger(Number(v))) return true; 
            return 'Должно быть числом'
        },
    }),
    setup(props, { emit }) {
        const root = getCurrentInstance().proxy
        const { route } = useRouter(root)
        const project = computed(() => root.$store.getters.projectById(route.value.params.id))
        let visitsCountCondition = reactive({
            min: props.value.visitsCountCondition.min,
            max: props.value.visitsCountCondition.max,
        })
        const triggerValue = ref(props.value)
        const key = ref(0)
        const delay = ref(props.value.delay || '')
        const clear = () => {
            delay.value = ''
            visitsCountCondition.min = ''
            visitsCountCondition.max = ''
            key.value++
        }
        const updateTrigger = () => {
            triggerValue.value.visitsCountCondition = visitsCountCondition
            triggerValue.value.delay = delay.value
            emit('update-trigger', triggerValue.value)
        }
        const validateMin = (e) => {
            let value = e.target.value;
            value = Math.abs(Math.floor(value || 0));
            visitsCountCondition.min = value;
        };

        const validateMax = (e) => {
            let value = e.target.value;
            value = Math.abs(Math.floor(value || 0));
            visitsCountCondition.max = value;
        };

        watch(
            () => visitsCountCondition,
            v => {
                props.value.visitsCountCondition = v
            },
            { deep: true, immediate: true }
        )
        watch(
            () => delay.value,
            v => {
                updateTrigger()
            },
            { deep: true, immediate: true }
        )
        onMounted(() => {})
        return {
            visitsCountCondition,
            delay,
            clear,
            key,
            triggerValue,
            updateTrigger,
            validateMin,
            validateMax
        }
    },
})
