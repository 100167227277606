<template>
    <div class="content-wrapper">
        <div class="content">
            <div class="successfullBanner mt-6">
                <div class="bannerImage">
                    <v-img width="184" :src="require('@/assets/img/onBoarding/success-hand.png')"> </v-img>
                </div>
                <div class="bannerContent ml-6">
                    <div class="bannerText">
                        <div class="bannerTitle">Поздравляем, ваш проект настроен!</div>
                        <div class="text" style="font-size: 16px; font-weight: normal">
                            По своей сути рыбатекст является альтернативой традиционному lorem ipsum, который
                            вызывает у некторых людей недоумение при попытках прочитать рыбу текст.
                        </div>
                    </div>
                    <div class="buttons-wrapper my-6">
                        <v-btn elevation="0" color="main" class="white--text" @click="goToProject">
                            НАЧАТЬ РАБОТУ</v-btn
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OnBoarding from '@/components/project/onBoarding/OnBoarding.vue'

import useRouter from '@/hooks/useRouter'
import { useProject } from '@root/src/hooks/useProject'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'

export default {
    name: 'SuccessfullView',
    components: {
        OnBoarding,
    },
    setup() {
        const root = getCurrentInstance().proxy
        const router = useRouter()
        const { project } = useProject()
        console.log(project.value)
        const goToProject = async () => {
            project.value.isOnBoardingActive = false
            await root.$store.dispatch('project/updateProjectSettings', {
                projectId: project.value.id,
                settings: project.value,
            })
            router.router.push(`/project/${project.value.id}`)
        }

        return {
            goToProject,
        }
    },
}
</script>

<style lang="sass" scoped>
.successfullBanner
    display: flex
    flex-direction: row
    align-items: flex-start

.bannerImage
    margin-right: 20pxz

.bannerContent
    height: 100%
    display: flex
    flex-direction: column
    justify-content: space-between

.buttons-wrapper
    align-self: flex-end
    margin-top: 10px

.content-wrapper
    margin-top: 40px

.bannerTitle
    font-size: 34px

.content
    align-items: center
    justify-content: center
    display: flex
    flex-direction: column
</style>