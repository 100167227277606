<template>
    <div class="auth">
        <h2 class="dashboard">Подключение Data Heroes к вашему Altegio</h2>
        <h2>Выполните <a
            href="http://help.dataheroes.pro/?slug=987-podklyuchenie-altegio"
            target="_blank"
            >инструкцию</a
        >
        и нажмите кнопку далее</h2>
        <v-row class="ma-0">
            <div class="btn-wrap">
                <v-btn
                  color="white"
                  class="black--text to-crm-select-btn"
                  @click="$parent.$emit('goBackToCrm')"
                >Назад к выбору CRM</v-btn>
                <v-btn color="main" class="white--text" @click="loginMeth">Далее</v-btn>
            </div>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import { login } from '@/api/connectors/altegio'
import cfg from '../../../../config'
import { CONNECTOR_TYPES } from '@root/src/vars/general'

export default {
    name: 'AltegioAuth',
    components: { TextField, HintIcon },
    data: () => ({}),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
    },
    methods: {
        async loginMeth() {
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.altegio,
            })
            //Дальше логин со стандартными кредсами
            const isLogin = await login({
                projectId,
                connector,
            })
            if (isLogin) {
                this.$store.commit('project/setLoggedAddingConnector', connector)
                this.$parent.$emit('login')
            }
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: flex-start
    align-items: flex-start
    flex-direction: column
    gap: 12px
.btn-wrap
    margin-top: 12px
    display: flex
    gap: 20px
</style>
