var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content-wrapper"},[_c('Breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"justify":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"main"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"content"},[(_vm.pendingConnector && _vm.pendingConnector.connectorType === _vm.CONNECTOR_TYPES.profSalon)?_c('ProfSalonResolver',{attrs:{"pendingConnector":_vm.pendingConnector}}):_vm._e(),(_vm.pendingConnector && _vm.pendingConnector.connectorType === _vm.CONNECTOR_TYPES.yClients)?_c('YclientsResolver',{attrs:{"pendingConnector":_vm.pendingConnector}}):_vm._e(),(_vm.pendingConnector && _vm.pendingConnector.connectorType === _vm.CONNECTOR_TYPES.altegio)?_c('AltegioReolver',{attrs:{"pendingConnector":_vm.pendingConnector}}):_vm._e(),(
                _vm.pendingConnector &&
                    (_vm.pendingConnector.connectorType === _vm.CONNECTOR_TYPES.profSalonBat ||
                        _vm.pendingConnector.connectorType === _vm.CONNECTOR_TYPES.profSalonBatV2)
            )?_c('ProfSalonBatResolver',{attrs:{"pendingConnector":_vm.pendingConnector}}):_vm._e(),(
                _vm.pendingConnector &&
                    _vm.pendingConnector.connectorType === _vm.CONNECTOR_TYPES.arnicaV2
            )?_c('ArnicaResolver',{attrs:{"pendingConnector":_vm.pendingConnector}}):_vm._e(),(
                _vm.pendingConnector &&
                    _vm.pendingConnector.connectorType === _vm.CONNECTOR_TYPES.dentalPro
            )?_c('DentalProResolver',{attrs:{"pendingConnector":_vm.pendingConnector}}):_vm._e(),_c('div',{staticClass:"send-comment"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],attrs:{"placeholder":"сообщение клиенту"},domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing)return;_vm.comment=$event.target.value}}}),_c('v-btn',{staticClass:"black--text auth-bth",attrs:{"color":"white"},on:{"click":_vm.sendComment}},[_vm._v(" Попросить изменить данные ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }