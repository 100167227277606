
import { PropType, computed, defineComponent, onMounted, ref, onBeforeMount, watch } from 'vue'
import * as _ from 'lodash'
import AdvancedMessageEditor from '../../../../ui/AdvancedMessageEditor.vue'
import useRouter from '../../../../../hooks/useRouter'
import FileUploader from '../../../../ui/FileUploader.vue'

export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    components: { AdvancedMessageEditor, FileUploader },
    setup(props, { emit }) {
        const { route } = useRouter()
        const media = ref(props?.value?.media)
        const inputHandler = ev => {
            console.log(ev)
            props.value.media = {...ev}
        }
        const fileInput = ref(null)

        const clearFileInChild = () => {
            if (fileInput.value) {
                fileInput.value.clearFile()
            }
        }
        watch(
            () => props.value.media,
            v => {
                if (Object.keys(v)?.length === 0 && Object.keys(media.value).length > 0 ) {
                    clearFileInChild()
                    media.value = {}                    
                }
            },
            {deep: true, immediate: true}
        )
        return {
            isUndefined: _.isUndefined,
            inputHandler,
            media,
            fileInput,
            clearFileInChild
        }
    },
    computed: {
        isActivation(): any {
            const currentURL = this.$router.currentRoute.fullPath
            return currentURL.includes('/GCB2/activation')
        },
    },
})
