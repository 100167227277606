<template>
    <div class="auth">
        <div class="form-block pa-10">
            <v-row class="mx-0 mb-4" justify="center" align="center">
                <h2>{{ currentAddingConnector.name }}</h2>
            </v-row>
            <v-form v-model="valid">
                <v-row class="ma-0">
                    <TextField
                        type="password"
                        color="main"
                        filled
                        v-model="token"
                        label="Токен"
                        required
                    ></TextField>

                    <TextField
                        color="main"
                        filled
                        v-model="companyId"
                        label="ID компании"
                        required
                    ></TextField>
                </v-row>
                <p v-if="validLogin === false" class="error--text">Не верные данные</p>
                <v-btn
                    color="main"
                    class="white--text"
                    @click="login"
                    :disabled="btnDisabled"
                    :loading="loading"
                    >Войти</v-btn
                >
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import cfg from '../../../../config'
import { login } from '@api/connectors/dikidi'
import { CONNECTOR_TYPES } from '@/vars/general'
export default {
    components: { TextField, HintIcon },
    data: () => ({
        validLogin: null,
        valid: false,
        token: '',
        companyId: '',
        loading: false,
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        btnDisabled() {
            if (!this.token || !this.companyId) {
                return true
            }
            return false
        },
    },
    methods: {
        async login() {
            this.loading = true
            const credentials = {
                token: this.token,
                companyId: this.companyId,
            }
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.dikidi,
            })
            const isLogin = await login(connector, credentials)

            if (isLogin) {
                this.$store.commit(
                    'project/setLoggedAddingConnector',
                    Object.assign(connector, { companyId: this.companyId })
                )
                this.$parent.$emit('login')
            }
            this.validLogin = isLogin
            if (isLogin) {
                this.$parent.$emit('login')
            } else {
                this.$store.dispatch('callNotify', 'Ошибка')
            }
            this.loading = false
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: center
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
</style>
