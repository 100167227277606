<template>
    <div class="connectorSettingsCard" @click="$emit('selectConnector')">
        <v-img contain v-if="avatarImg" class="cardImage" transition="slide-x-transition" :src="avatarImg" />
        <div
            v-else
            :style="{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }"
        >
            <span
                :style="{
                    backgroundColor: computedConnectorColor(connector),
                    borderRadius: '100%',
                    width: '60px',
                    height: '60px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }"
            >
                {{ computedConnectorSymbol(connector) }}
            </span>

            <span class="ml-5" :style="{ fontSize: '20px' }">
                {{ computedConnectorName(connector) }}
            </span>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, ref } from 'vue'
import useRouter from '@/hooks/useRouter'

export default {
    name: 'ConnectorSettingsCard',
    props: {
        connector: Object,
    },
    setup(props) {
        const root = getCurrentInstance().proxy
        const store = root.$store
        const avatarImg = ref(null)
        try {
            avatarImg.value = require('@/assets/img/onBoarding/CRMImages/' + props.connector.type + '.png')
        } catch (e) {
            avatarImg.value = null
        }
        const connectorAvatarByType = (connectorType) => store.getters.connectorAvatarByType(connectorType)
        const computedConnectorSymbol = (connector) =>
            store.getters.connectorAvatarByType(connector.type).symbol
        const computedConnectorName = (connector) => store.getters.connectorAvatarByType(connector.type).name
        const computedConnectorColor = (connector) =>
            store.getters.connectorAvatarByType(connector.type).color
        const computedConnectorImage = (connector) =>
            store.getters.connectorAvatarByType(connector.type).image

        return {
            connectorAvatarByType,
            computedConnectorSymbol,
            computedConnectorColor,
            computedConnectorImage,
            computedConnectorName,
            avatarImg,
        }
    },
}
</script>

<style lang="sass">
@import '@/vars.sass'
.connectorSettingsCard
    width: 100%
    height: 90px
    display: flex
    align-items: center
    justify-content: center
    border: 1px solid $light-gray-4
    border-radius: 4px
    font-weight: bold
    font-size: 28px
    user-select: none
    cursor: pointer
    &:hover
        background-color: $light-gray-4
    .connectorSettingsCard:hover
        opacity: 70%
    .cardImage
        max-width: 164px
        max-height: 68px
</style>