<template>
    <div class="auth">
        <div v-if="infoStep">
            <h2>Для интеграции с нами вам необходимо:</h2>
            <h2>1. Перейти по ссылке: <a href="https://klientiks.ru/clientix/settings/account" target="_blank">https://klientiks.ru/clientix/settings/account</a></h2>
            <h2>2. Перейти в раздел API</h2>
            <h2>3. Поставить галку в разделе “Включить API”</h2>
            <h2>4. Скопировать ID аккаунта, ID юзера, Токен авторизации</h2>
            <div class="btn-wrap">
                <v-btn
                  color="white"
                  class="black--text to-crm-select-btn"
                  @click="$parent.$emit('goBackToCrm')"
                >Назад к выбору CRM</v-btn>
                <v-btn
                  color="main"
                  class="white--text"
                  @click="toggleinfo"
                  :loading="loading"
                >Далее</v-btn>
            </div>

        </div>
        <div v-else class="auth">
            <h2>Вставте в поля ниже скопированные ID и токен</h2>
            <div  class="form-block pa-10">
            <v-form v-model="valid">
                <v-row class="ma-0">
                    <TextField
                        color="main"
                        filled
                        v-model="creds.user_id"
                        label="ID юзера"
                        required
                    ></TextField>
                    <TextField
                        color="main"
                        filled
                        v-model="creds.account_id"
                        label="ID аккаунта"
                        required
                    ></TextField>
                    <TextField
                        color="main"
                        filled
                        v-model="creds.access_token"
                        type="password"
                        label="Токен авторизации"
                        required
                    ></TextField>
                </v-row>
                <p v-if="validLogin === false" class="error--text">Не верные данные</p>
                <div class="btn-wrap">
                    <v-btn
                      color="white"
                      class="black--text to-crm-select-btn"
                      @click="toggleinfo"
                    >Назад</v-btn>
                    <v-btn
                      color="main"
                      class="white--text"
                      @click="login"
                      :disabled="btnDisabled"
                      :loading="loading"
                    >Войти</v-btn
                    >
                </div>
            </v-form>
        </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import { login } from '@api/connectors/klientix'

export default  {
    components: { TextField, HintIcon },
    data: () => ({
        validLogin: null,
        valid: false,
        creds: {
            access_token: '',
            user_id: '',
            account_id: '',
        },
        loading: false,
        infoStep: true
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),

        btnDisabled() {
            if (Object.keys(this.creds).some(key => !this.creds[key])) {
                return true
            }
            return false
        },
    },
    methods: {
        async login() {
            this.loading = true

            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: this.currentAddingConnector.type ?? this.currentAddingConnector,
            })
            const isLogin = await login(connector, this.creds)

            if (isLogin) {
                this.$store.commit('project/setLoggedAddingConnector', connector)
                this.$parent.$emit('login')
            }
            this.validLogin = isLogin
            this.loading = false
        },
        async toggleinfo() {
            this.infoStep = !this.infoStep
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: flex-start
    align-items: center
    flex-direction: column
    gap: 12px
    max-width: 1200px
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
.btn-wrap
    margin-top: 12px
    display: flex
    gap: 20px
</style>
