
import { defineComponent, ref, PropType, computed, shallowRef, watch, getCurrentInstance, onMounted } from 'vue'
import useRouter from '../../../../hooks/useRouter'
import { Project } from '../../../../types/main'
import * as _ from 'lodash'
import BaseInputOutlined from '../../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../../inputs/CustomActivatorSelect.vue'
import TimeInput from '../../../inputs/TimeInput.vue'
import { EditedActivation } from '../../../../types/GCB2'
import { CONNECTOR_TYPES, MESSAGING_CONNECTOR_TYPES } from '../../../../vars/general'
import api from '@root/src/api/api'

export default defineComponent({
    name: 'AutomailSettings',
    components: { BaseInputOutlined, CustomActivatorSelect, TimeInput },
    props: {
        value: { type: Object as PropType<EditedActivation>, required: true },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const { route } = useRouter(root)
        const project = computed<Project>(() => root.$store.getters.projectById(route.value.params.id))
        const preferedBot = computed<String>(() => _.get(props.value.extra, 'preferedBot') ?? 'default')
        const formInput = (e: any) => {
            emit('validation-updated', true)
        }
        const changePreferedBot = (preferedBot: String) => {
            let newVal = null
            if (preferedBot === 'default') {
                newVal = Object.assign({}, props.value, {
                    extra: _.omit(props.value.extra, 'preferedBot'),
                })
            } else {
                newVal = Object.assign({}, props.value, {
                    extra: _.merge(props.value.extra, { preferedBot: preferedBot }),
                })
            }
            emit('input', newVal)
        }
        const connectorsHashMapWithKeysByConnectorsType = computed(
            () => root.$store.getters.connectorsHashMapWithKeysByConnectorsType
        )
        let availableBots = ref([])
        onMounted(async () => {
            const filteredConnectors = project.value.connectors.filter(el =>
                MESSAGING_CONNECTOR_TYPES.includes(el.connectorType)
            )
            for (const item of filteredConnectors) {
                let name = ''
                const isSpareConnector = item?.connectorId?.includes('spare')
                if (item.connectorType === CONNECTOR_TYPES.whatsappBasis) {
                    const [app, error] = await api.networkFixer
                        .getApp({ connectorId: item.connectorId })
                        .then(res => [res.data!.data, res.error])
                    if (app.channel?.['wApi-telegram']?.credentials) {
                        name = 'Telegram'
                    } else {
                        name = 'Whatsapp'
                    }
                } else {
                    name =
                        connectorsHashMapWithKeysByConnectorsType.value[item.connectorType].name ??
                        item.connectorType
                }
                availableBots.value.push({
                    id: item.connectorId,
                    name: isSpareConnector ? `${name} (запасной)` : name,
                })
            }
            availableBots.value.push({ id: 'default', name: 'Не определено' })
        })

        return { project, availableBots, preferedBot, formInput, changePreferedBot }
    },
})
