import { defineStore } from 'pinia'

const DEFAULT_VALUES = {
    title: 'ВНИМАНИЕ!',
    type: 'text',
    text: '',
    color: 'main',
    link: [],
    linkText: [],
    styleClass: '',
    buttons: null,
}
interface OpenModalParams {
    title?: string
    type?: string
    text?: string
    color?: string
    link?: string[]
    linkText?: string[]
    styleClass?: string
    buttons?: ModalButtonsParams
}
export interface ModalButtonsParams {
    okName: string,
    cancelName: string,
    okCallback(): void
}

export const useModalStore = defineStore('modal', {
    state: () => ({
        active: false as Boolean,
        title: DEFAULT_VALUES.title,
        type: DEFAULT_VALUES.type,
        text: DEFAULT_VALUES.text as string,
        color: DEFAULT_VALUES.color,
        link: DEFAULT_VALUES.link as string[],
        linkText: DEFAULT_VALUES.linkText as string[],
        styleClass: DEFAULT_VALUES.styleClass as string,
        buttons: DEFAULT_VALUES.buttons as ModalButtonsParams,
    }),
    actions: {
        clearState() {
            this.active = false
            setTimeout(() => {
                this.title = DEFAULT_VALUES.title
                this.type = DEFAULT_VALUES.type
                this.text = DEFAULT_VALUES.text
                this.color = DEFAULT_VALUES.color
                this.link = DEFAULT_VALUES.link
                this.linkText = DEFAULT_VALUES.linkText
                this.styleClass = DEFAULT_VALUES.styleClass
                this.buttons = DEFAULT_VALUES.buttons
            }, 150)
        },
        open(params: OpenModalParams) {
            this.title = params.title || DEFAULT_VALUES.title
            this.type = params.type || DEFAULT_VALUES.type
            this.text = params.text || DEFAULT_VALUES.text
            this.color = params.color || DEFAULT_VALUES.color
            this.link = params.link || DEFAULT_VALUES.link
            this.styleClass = params.styleClass || DEFAULT_VALUES.styleClass
            this.buttons = params.buttons || DEFAULT_VALUES.buttons
            this.linkText = params.linkText || (this.link ?? DEFAULT_VALUES.linkText)
            this.active = true
        },
        close() {
            this.clearState()
        },
    },
})
