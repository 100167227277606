<template>
    <div class="auth">
        <div v-if="infoStep" class="auth infoStep">
            <h2>1. Перейти в чат с техподдержкой, например, в телеграм:
            <a href="https://t.me/salon1c_support_bot">https://t.me/salon1c_support_bot</a>
            </h2>
            <h2>2. Напишите любое слово в бот (например «Добрый день!»)</h2>

            <h2>3. В появившемся меню выберите пункт «Другой вопрос»</h2>

            <h2>4. Отправьте следующий запрос: «Добрый день! У нас облачная версия 1с:Салон красоты. Наш ИНН: &lt;Вставьте ваш ИНН&gt;. Вы могли бы прислать наш ключ API и ID наших филиалов?»</h2>

            <h2>5. В ответном сообщении вам должны прислать ключ API и ID филиалов</h2>


            <div class="btn-wrap">
                <v-btn
                  color="white"
                  class="black--text to-crm-select-btn"
                  @click="$parent.$emit('goBackToCrm')"
                >Назад к выбору CRM</v-btn>
                <v-btn
                  color="main"
                  class="white--text"
                  @click="toggleinfo"
                >Далее</v-btn>
            </div>
        </div>
        <div v-else class="auth">
            <h2>Вставьте присланные вам ID филиала и ключ API в форму ниже для завершения интеграции.</h2>
            <div class="form-block pa-10">
            <v-form v-model="valid">
                <v-row class="ma-0">
                    <TextField
                        color="main"
                        filled
                        v-model="creds.salonId"
                        label="salon id"
                        required
                    ></TextField>
                    <TextField
                        color="main"
                        filled
                        v-model="creds.apiKey"
                        type="password"
                        label="Ключ api"
                        required
                    ></TextField>
                </v-row>
                <p v-if="validLogin === false" class="error--text">Не верные данные</p>
                <div class="btn-wrap">
                    <v-btn
                      color="white"
                      class="black--text"
                      @click="toggleinfo"
                      :loading="loading"
                    >Назад</v-btn>
                    <v-btn
                      color="main"
                      class="white--text"
                      @click="login"
                      :disabled="btnDisabled"
                      :loading="loading"
                    >Войти</v-btn>
                </div>
            </v-form>
        </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import { login } from '@api/connectors/cloud1C'
import { CONNECTOR_TYPES } from '@/vars/general'
export default {
    components: { TextField, HintIcon },
    data: () => ({
        validLogin: null,
        valid: false,
        creds: {
            apiKey: '',
            salonId: '',
        },
        loading: false,
        infoStep: true,
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),

        btnDisabled() {
            if (Object.keys(this.creds).some(key => !this.creds[key])) {
                return true
            }
            return false
        },
    },
    methods: {
        async login() {
            this.loading = true

            const projectId = this.$router.currentRoute.params.id
            console.log('this.currentAddingConnector', this.currentAddingConnector)
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: this.currentAddingConnector.type ?? this.currentAddingConnector,
            })
            const isLogin = await login(connector, this.creds)

            if (isLogin) {
                this.$store.commit(
                    'project/setLoggedAddingConnector',
                    Object.assign(connector, { salonId: this.creds.salonId })
                )
                this.$parent.$emit('login')
            }
            this.validLogin = isLogin
            this.loading = false
        },
        toggleinfo() {
            this.infoStep = !this.infoStep
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: flex-start
    align-items: center
    flex-direction: column
    gap: 12px
    max-width: 1200px
.infoStep
    align-items: flex-start

.form-block
    border: 1px solid #0000001F
    min-width: 360px
    max-width: 460px
.btn-wrap
    margin-top: 12px
    display: flex
    gap: 20px
</style>
