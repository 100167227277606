
    import Vue, { PropType, PropOptions } from 'vue'
    import { PendingConnector } from '@root/src/types/main'
    import { getCredential } from '@api/admin'
    import TextField from '../../../../components/inputs/TextField.vue'
    import SpinnerLoader from '../../../../components/SpinnerLoader.vue'
    import api from '@root/src/api/api'
    import { login } from '@api/connectors/dentalPro'
    import { mapStores } from 'pinia'
    import { useAdminStore } from '@/store/stores/admin'
    import { useConfirmStore } from '@/store/stores/confirm'
    import store from '@root/src/store'
    import useRouter from '@root/src/hooks/useRouter'
    import { CONNECTOR_TYPES } from '@root/src/vars/general'
    export default Vue.extend({
        name: 'PendingConnectorResolverProfSalon',
        components: { TextField, SpinnerLoader },
        props: {
            pendingConnector: Object as () => PendingConnector,
        },
        data() {
            return {
                loading: true,
                validLogin: null,
                valid: false,
                credentials: {
                    token: '',
                    secret: '',
                    url: '',
                    companyId: '',
                    login: '',
                    password: '',
                },
            }
        },
        computed: {
            ...mapStores(useAdminStore),
        },
        methods: {
            async resolve() {
                this.loading = true
                const isLogin = await login(this.pendingConnector, this.credentials)
                this.validLogin = isLogin
                this.loading = false

                if (!isLogin) {
                    this.$store.dispatch('callNotify', 'Не верные данные')
                } else {
                    this.adminStore.deletePendingConnector(this.pendingConnector.id)
                    this.$router.push('/admin/pendingConnectors')
                }
            },
            async goToAutorization() {
                store.commit('project/updateCurrentAddingConnector', this.pendingConnector.connectorType)
                this.$router.push(`/project/${this.pendingConnector.projectId}/onBoarding/AuthorizationInCRMSystem`)
            },
        },
        async created() {
            this.loading = true
            const [login, password] = await Promise.all([
                getCredential({ connectorId: this.pendingConnector.connectorId, name: 'login' }).then(
                  res => res.data
                ),
                getCredential({ connectorId: this.pendingConnector.connectorId, name: 'password' }).then(
                  res => res.data
                ),
            ])
            this.credentials.login = login
            this.credentials.password = password
            this.loading = false
        },
    })
