var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"connectorSettingsCard",on:{"click":function($event){return _vm.$emit('selectConnector')}}},[(_vm.avatarImg)?_c('v-img',{staticClass:"cardImage",attrs:{"contain":"","transition":"slide-x-transition","src":_vm.avatarImg}}):_c('div',{style:({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        })},[_c('span',{style:({
                backgroundColor: _vm.computedConnectorColor(_vm.connector),
                borderRadius: '100%',
                width: '60px',
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            })},[_vm._v(" "+_vm._s(_vm.computedConnectorSymbol(_vm.connector))+" ")]),_c('span',{staticClass:"ml-5",style:({ fontSize: '20px' })},[_vm._v(" "+_vm._s(_vm.computedConnectorName(_vm.connector))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }