<template>
    <div class="auth">
        <h2>Для интеграции с нами вашему разработчику нужно разработать API, которое будет возвращать данные согласно <a href="https://docs.google.com/document/d/1hJIbZNC_HlfQSxq4McPwjFSVAEUtDZnvU1QIUpgHUvs/edit#heading=h.aq3gurnmlgy6" target="_blank">инструкции</a></h2>
        <div class="btn-wrap">
            <v-btn
              color="white"
              class="black--text to-crm-select-btn"
              @click="$parent.$emit('goBackToCrm')"
            >Назад к выбору CRM</v-btn>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, ref } from 'vue'
import useRouter from '@/hooks/useRouter'

export default {
    name: 'OtherConnector',
    setup() {
        return {
        }
    },
}
</script>

<style lang="sass">
@import '@/vars.sass'
.auth
    display: flex
    justify-content: flex-start
    align-items: center
    flex-direction: column
    gap: 12px
    max-width: 1200px
    margin: auto
    margin-top: 40px
.btn-wrap
    margin-top: 12px
    display: flex
    gap: 20px
</style>