
    import { computed, defineComponent, getCurrentInstance, ref, watch } from 'vue'
import { useBillingStore } from '../../../../store/stores/billing'
import { useProject } from '../../../../hooks/useProject'
import { useUser } from '@/hooks/useUser'
import api from '../../../../api/api'
import SpinnerLoader from '../../../../components/SpinnerLoader.vue'
import { declOfNum, openInCurrentTab, openInNewTab } from '../../../../utils'
    import { MarketplaceIntegration } from '@t/marketplace'
    import { SolvePaymentParams } from '@api/marketplace'

export default defineComponent({
    components: { SpinnerLoader },
    props: {},
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const billingStore = useBillingStore()
        const { project } = useProject()
        const loading = ref(true)
        const paymentLinkLoading = ref(false)
        const solvedPaymentData = ref<any>()
        const paymentPayloadId = ref<number>()
        const { user } = useUser()

        const modalOpened = computed({
            get: () => billingStore.paymentModal.opened,
            set: (v: boolean) => billingStore.setPaymentModalOpened(v),
        })
        const renewal = computed({
            get: () => billingStore.paymentModal.renewal,
            set: (v: boolean) => billingStore.setPaymentModalOpened(billingStore.paymentModal.opened, v),
        })
        const currentTariffData = computed(() => billingStore.getCurrentTariff(project.value.id))
        const selectedTariff = computed(() => billingStore.getTariffById(billingStore.selectedTariffId!)!)
        const formattedCurrentTariffendOfUse = computed(() =>
            new Date(currentTariffData.value.endOfUse).toLocaleDateString()
        )
        const formattedUnusedDiscount = computed(() => {
            if (solvedPaymentData.value.price?.unusedDiscount) {
                return billingStore.formatPrice(solvedPaymentData.value.price?.unusedDiscount)
            }
        })
        const currentTariffFormattedPrice = computed(() => {
            if (!currentTariffData.value) return '0 ₽'
            return billingStore.formatPrice(
                billingStore.getTarrifRegularPrice(
                    currentTariffData.value.integrationId,
                    currentTariffData.value.periodInMonths.toString()
                )
            )
        })
        const refundDescription = computed(() => {
            if (solvedPaymentData.value.price?.toRefund) {
                return `${billingStore.formatPrice(
                    solvedPaymentData.value.price?.toRefund
                )} вернется вам на карту после обращения в техническую поддержку`
            }
        })
        const selectedTariffFormattedFirstPaymentPrice = computed(() =>
            billingStore.formatPrice(
                selectedTariff.value.complexPrice.monthPeriod[billingStore.selectedSubscriptionPeriod!]
                    .firstPayment.sum
            )
        )
        const selectedTariffFormattedRegularPaymentPrice = computed(() =>
            billingStore.formatPrice(
                currentTariffData.value.price.regularPay
            )
        )
        const formattedResultPrice = computed(() =>
            billingStore.formatPrice(solvedPaymentData.value.price?.toPay)
        )
        const formattedBalanceOfAccount = computed(() =>
          billingStore.formatPrice(solvedPaymentData.value.price?.balanceOfAccount)
        )
        const selectedTariffPaymentDescription = computed(() => {
            const firstPayment =
                selectedTariff.value.complexPrice.monthPeriod[billingStore.selectedSubscriptionPeriod!]
                    .firstPayment
            const firstPaymentIsSpecial =
                firstPayment.months !== Number(billingStore.selectedSubscriptionPeriod)
            if (firstPaymentIsSpecial && solvedPaymentData.value.price?.isFirstPurchase) {
                const regularPaymentFormattedDate = new Date(
                    solvedPaymentData.value.price?.regularPayAfter
                ).toLocaleDateString()
                return `Первое списание за ${firstPayment.months} ${declOfNum(firstPayment.months, [
                    'месяц',
                    'месяца',
                    'месяцев',
                ])}, далее с ${regularPaymentFormattedDate} ежемесячно по ${billingStore.formatPrice(
                    solvedPaymentData.value.price?.regularPay
                )}`
            }
            return
        })
        const formattedTodayDate = computed(() => new Date().toLocaleDateString())

        const promoName = computed(() =>
          solvedPaymentData.value?.price.promocodeData?.promocode ?
            solvedPaymentData.value?.price.promocodeData?.promocode + ' -' + solvedPaymentData.value?.price.promocodeData?.discountPercent + '%':
            ''
        )

        const formattedPromoDiscount = computed(() =>
          billingStore.formatPrice(solvedPaymentData.value.price?.promocodeData.promocodeDiscount)
        )

        watch(modalOpened, async v => {
            if (v) {
                await initData()
            }
        })

        const closeModal = () => {
            billingStore.setPaymentModalOpened(false)
        }
        const initData = async () => {
            loading.value = true
            const solveMethod: (params: SolvePaymentParams) => Promise<{data: MarketplaceIntegration[], error:any}> =
              billingStore.paymentModal.renewal ?
                api.marketplace.solveRenewalSubscription :
                api.marketplace.solvePayment

            const { data, error } = await solveMethod ({
                projectId: project.value.id,
                marketplaceIntegrationId: selectedTariff.value.id,
                subscriptionPeriod: billingStore.selectedSubscriptionPeriod!,
                userId: user.value.sub,
            })
            if (error) {
                root.$store.dispatch('callNotify', 'Ошибка при загрузке данных о платеже')
                loading.value = false
                return
            }
            solvedPaymentData.value = data
            paymentPayloadId.value = (data as any).paymentPayloadId
            loading.value = false
        }

        const makePayment = async () => {
            paymentLinkLoading.value = true
            const { data, error } = await api.marketplace.installIntegration({
                paymentPayloadId: paymentPayloadId.value,
                integrationId: selectedTariff.value.id,
                projectId: project.value.id,
                subscriptionPeriod: billingStore.selectedSubscriptionPeriod!,
            } as any)
            console.log({ data })
            if (error) {
                root.$store.dispatch('callNotify', 'Ошибка при попытке оплаты')
                paymentLinkLoading.value = false
                return
            }
            if (data?.status === 'needPayment') {
                openInCurrentTab(data.paymentLink)
            }
            paymentLinkLoading.value = false
            if (data?.status === 'installed') {
                root.$store.dispatch('callNotify', 'Подписка успешно оформлена')
                window.location.reload()
            }
        }

        return {
            billingStore,
            modalOpened,
            renewal,
            selectedTariff,
            formattedTodayDate,
            loading,
            selectedTariffFormattedFirstPaymentPrice,
            selectedTariffFormattedRegularPaymentPrice,
            selectedTariffPaymentDescription,
            currentTariffData,
            formattedCurrentTariffendOfUse,
            formattedUnusedDiscount,
            refundDescription,
            closeModal,
            formattedResultPrice,
            makePayment,
            paymentLinkLoading,
            solvedPaymentData,
            promoName,
            formattedPromoDiscount,
            formattedBalanceOfAccount
        }
    },
})
