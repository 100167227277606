
    import Vue, { PropType, PropOptions } from 'vue'
    import { PendingConnector } from '@root/src/types/main'
    import { getCredential } from '@api/admin'
    import TextField from '../../../../components/inputs/TextField.vue'
    import SpinnerLoader from '../../../../components/SpinnerLoader.vue'
    import api from '@root/src/api/api'
    import { mapStores } from 'pinia'
    import { useAdminStore } from '@/store/stores/admin'
    import { useConfirmStore } from '@/store/stores/confirm'
    import store from '@root/src/store'
    import useRouter from '@root/src/hooks/useRouter'
    export default Vue.extend({
        name: 'PendingConnectorResolverProfSalon',
        components: { TextField, SpinnerLoader },
        props: {
            pendingConnector: Object as () => PendingConnector,
        },
        data() {
            return {
                loading: true,
                router: null,
                credentials: { login: '', password: '', partnerToken: '', userToken: '' },
            }
        },
        computed: {
            ...mapStores(useAdminStore),
        },
        methods: {
            async resolve() {
                const { data, error } = await api.connectors.arnicaV2.login({
                    userToken: this.credentials.userToken,
                    connectorId: this.pendingConnector.connectorId,
                    partnerToken: this.credentials.partnerToken,
                })

                if (error) {
                    this.$store.dispatch('callNotify', 'Не верные данные')
                } else {
                    this.adminStore.deletePendingConnector(this.pendingConnector.id)
                    this.$router.push('/admin/pendingConnectors')
                }
            },
            async goToAutorization() {
                store.commit('project/updateCurrentAddingConnector', this.pendingConnector.connectorType)
                this.$router.push(`/project/${this.pendingConnector.projectId}/onBoarding/AuthorizationInCRMSystem`)
            },
        },
        async created() {
            this.loading = true
            const [login, password, partnerToken] = await Promise.all([
                getCredential({ connectorId: this.pendingConnector.connectorId, name: 'login' }).then(
                  res => res.data
                ),
                getCredential({ connectorId: this.pendingConnector.connectorId, name: 'password' }).then(
                  res => res.data
                ),
                getCredential({ connectorId: this.pendingConnector.connectorId, name: 'partnerToken' }).then(
                  res => res.data
                ),
            ])
            this.credentials.login = login
            this.credentials.password = password
            this.credentials.partnerToken = partnerToken
            this.loading = false
        },
    })
