<template>
    <div class="cards-view">
        <template v-for="i in Math.ceil(paginatedProjects.length / 3)">
            <v-row :key="i" class="ma-0">
                <ProjectCard
                    v-for="(project, j) in paginatedProjects.slice((i - 1) * 3, i * 3)"
                    :key="project.id"
                    :class="j === 1 ? 'mx-4' : ''"
                    :project="project"
                >
                </ProjectCard>
            </v-row>
        </template>
        <v-row v-if="projects.length > 6" justify="end" align="center" class="mt-4 flex-nowrap">
            <div style="flex-basis: 100%"></div>
            <span class="select-label">Проектов на странице</span>
            <v-select
                class="mt-2"
                :items="itemsOptions"
                item-text="text"
                item-value="value"
                v-model="itemsPerPage.value"
            />
            <v-pagination v-model="currentPage" :length="totalPages" :total-visible="7"/>
        </v-row>
    </div>
</template>

<script>
import ProjectCard from './ProjectCard'
export default {
    name: 'CardsView',
    props: {
        projects: {
            type: Array,
            default: [],
        },
    },
    components: { ProjectCard },
    data: () => ({
        currentPage: 1,
        itemsPerPage: {text: 15, value: 15},
        itemsOptions: [{text: 6, value: 6}, {text: 15, value: 15}, {text: 24, value: 24}],
    }),
    computed: {
        totalPages() {
            return Math.ceil(this.projects.length / this.itemsPerPage.value)
        },
        paginatedProjects() {
            const start = (this.currentPage - 1) * this.itemsPerPage.value
            const end = start + this.itemsPerPage.value
            return this.projects.slice(start, end)
        },
    },
    watch: {
        'itemsPerPage.value'(newVal) {
            const newTotalPages = Math.ceil(this.projects.length / newVal)
            if (this.currentPage > newTotalPages) {
                this.currentPage = newTotalPages || 1
            }
            this.currentPage = 1
        },
        projects() {
            const newTotalPages = Math.ceil(this.projects.length / this.itemsPerPage.value)
            if (this.currentPage > newTotalPages) {
                this.currentPage = newTotalPages || 1
            }
        },
    },
    methods: {
        addUser(projectId) {
            this.$parent.$emit('addUser', projectId)
        },
        deleteProject(projectId) {
            this.$parent.$emit('deleteProject', projectId)
        },
        renameProject(projectId) {
            this.$parent.$emit('renameProject', projectId)
        },
        changeProjectImage(projectId) {
            this.$parent.$emit('changeProjectImage', projectId)
        },
    },

    created() {
        this.$on('deleteProject', this.deleteProject)
        this.$on('addUser', this.addUser)
        this.$on('renameProject', this.renameProject)
        this.$on('changeProjectImage', this.changeProjectImage)
    },
    mounted() {
        this.itemsOptions.push({text: `Все (${this.projects.length})`, value: this.projects.length})
    },
}
</script>

<style lang="sass" scoped>
thead
    background-color: #0000000b
::v-deep.cards-view
    .select-label
        font-size: 12px
        line-height: 16px
        padding-right: 16px
        color: #2A3B5199
    .v-pagination
        margin-left: 14px
        button
            width: 36px
            height: 36px
            border-radius: 100%
            box-shadow: none
            margin: 0 2px
            background-color: transparent
            color: #2A3B51DE
            font-size: 12px
            line-height: 16px
            &:hover
                background-color: #F4F4F4
            &:active
                background-color: #C0C0C0
    .v-select__slot
        font-size: 14px
        line-height: 20px
        color: #2A3B51DE

</style>
