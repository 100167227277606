
import qs from 'query-string'
import { computed, defineComponent, onMounted, ref, getCurrentInstance } from 'vue'
import api from '@/api/api'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import useRouter from '@/hooks/useRouter'
import { useModalStore } from '@/store/stores/modal'
import { ImportantError } from '@/helpers/Error'
import ClientForm from '@/components/clientForm/ClientForm.vue'
export default defineComponent({
    components: { SpinnerLoader, ClientForm },
    setup(props, {}) {
        const root = getCurrentInstance()!.proxy
        const loading = ref(true)
        const { route, router } = useRouter(root)
        const enableSms = ref(false)
        const showClientForm = ref(false)
        const modalStore = useModalStore()
        const showConnectModal = computed(() => !loading.value && !showClientForm.value)
        const showClientFormModal = computed(() => !loading.value && showClientForm.value)
        onMounted(async () => {
            loading.value = true
            showClientForm.value = await api.clientForm.getClientFormExists().then(res => !res.data?.value)
            loading.value = false
        })
        const onAnswersSaved = () => {
            showClientForm.value = false
        }
        const clientFormExtra = computed(() => {
            const parsedQuery = qs.parseUrl(route.value.fullPath, { arrayFormat: 'index' }).query
            const salonIds = (parsedQuery.salon_ids ?? [route.value.query.salon_id]) as string[]
            return { salonIds, encodedUserData: route.value.query?.user_data }
        })
        const createProjects = async () => {
            loading.value = true
            const query = route.value.query
            const params = route.value.params
            const parsedQuery = qs.parseUrl(route.value.fullPath, { arrayFormat: 'index' }).query
            const salonIds = (parsedQuery.salon_ids ?? [query.salon_id]) as string[]
            const responses: any[] = []

            for (const salonId of salonIds) {
                try {
                    const response = await api.project.initFromMarketPlace({
                        type: params.connectorType,
                        encodedUserData: query.user_data,
                        userSign: query.user_data_sign,
                        salonId: salonId,
                        salonIds: parsedQuery.salon_ids,
                        enableSms: enableSms.value,
                    } as any)

                    if (response.error) {
                        new ImportantError('YClients init from marketplace error', { payload: response })
                        modalStore.open({ text: 'Ошибка подключения, обратитесь в техподдержку' })
                        return
                    }

                    responses.push(response)
                } catch (error) {
                    new ImportantError('Error during request to marketplace', { originalError: error })
                    modalStore.open({ text: 'Ошибка подключения, обратитесь в техподдержку' })
                    return
                }
            }
            await root.$store.dispatch('project/setProjects')
            const projectId = responses[0].data['project']['id']
            const project = responses[0].data['project']
            root.$store.commit('project/updateCurrentAddingConnector', 'whatsappBasis')

            router.replace(`/project/${projectId}/onBoarding`)
        }
        return {
            loading,
            enableSms,
            createProjects,
            showClientForm,
            onAnswersSaved,
            showConnectModal,
            showClientFormModal,
            clientFormExtra,
        }
    },
})
