<template>
    <div class="connectorSettingsCard" @click="$emit('selectConnector')">
        <span :style="{ fontSize: '20px' }">
            Другое
        </span>
    </div>
</template>

<script>
export default {
    name: 'OtherConnectorCard',
    setup() {
        return {
        }
    },
}
</script>

<style lang="sass">
@import '@/vars.sass'
.connectorSettingsCard
    width: 100%
    height: 90px
    display: flex
    align-items: center
    justify-content: center
    border: 1px solid $light-gray-4
    border-radius: 4px
    font-weight: bold
    font-size: 28px
    user-select: none
    cursor: pointer
    &:hover
        background-color: $light-gray-4
    .connectorSettingsCard:hover
        opacity: 70%
    .cardImage
        max-width: 164px
        max-height: 68px
</style>