var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"my-2 card-outter",attrs:{"width":"328","height":"301","elevation":"0","outlined":""}},[_c('div',{staticClass:"card-wrapper"},[_c('div',[_c('div',{staticClass:"card-title-wrapper"},[_c('div',{staticClass:"card-title",class:{
                        running: _vm.cardData.isRunning,
                        'not-running': !_vm.cardData.isRunning,
                    }},[_c('v-tooltip',{attrs:{"right":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"mb-2 font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.cardData.title)+" ")])]}}])},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.cardData.title))])])],1),_c('div',{staticClass:"menu-icon",class:{
                        running: _vm.cardData.isRunning,
                        'not-running': !_vm.cardData.isRunning,
                    }},[_c('TriggerCardMenu',{attrs:{"cardData":_vm.cardData},on:{"change-is-running":_vm.changeIsRunning,"delete-trigger":_vm.deleteTrigger}})],1)]),_c('div',{staticClass:"description-wrapper"},[_c('v-tooltip',{attrs:{"right":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.cardData.description))])]}}])},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.cardData.description))])])],1)]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"card-footer-actions mt-4"},[_c('div'),(_vm.checkRole(_vm.project.id, 'gcb2:bot:toogleIsRunning'))?_c('v-tooltip',{attrs:{"right":"","max-width":"400px","disabled":_vm.canActivateTrigger(_vm.cardData.id, _vm.activeTriggersIds).canActivate},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{attrs:{"hide-details":"","disabled":!_vm.canActivateTrigger(_vm.cardData.id, _vm.activeTriggersIds).canActivate,"input-value":_vm.cardData.isRunning},on:{"change":function($event){return _vm.changeIsRunning({ id: _vm.cardData.id, value: $event })}}})],1)]}}],null,false,883976221)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.canActivateTrigger(_vm.cardData.id, _vm.activeTriggersIds).errorText))])]):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }