
import { defineComponent, ref, PropType, computed, shallowRef, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import BaseInputOutlined from './BaseInputOutlined.vue'

const replaceBadInputs = (val: string) => {
    val = val.replace(/[^\dh:]/, '')
    val = val.replace(/^[^0-2]/, '')
    val = val.replace(/^([2-9])[4-9]/, '$1')
    val = val.replace(/^\d[:h]/, '')
    val = val.replace(/^([01][0-9])[^:h]/, '$1')
    val = val.replace(/^(2[0-3])[^:h]/, '$1')
    val = val.replace(/^(\d{2}[:h])[^0-5]/, '$1')
    val = val.replace(/^(\d{2}h)./, '$1')
    val = val.replace(/^(\d{2}:[0-5])[^0-9]/, '$1')
    val = val.replace(/^(\d{2}:\d[0-9])./, '$1')
    return val
}
const replaceBadInputsWithDays = (val: string) => {
    val = val.replace(/[^0-9:]/g, '')

    const parts = val.split(':')

    let days = 0,
        hours = 0,
        minutes = 0

    if (parts.length > 0) {
        days = Number(parts[0]) || 0
        days = Math.min(days, 7)
    }

    if (parts.length > 1) {
        hours = Number(parts[1]) || 0
        hours = Math.min(hours, 23)
    }

    if (parts.length > 2) {
        minutes = Number(parts[2]) || 0
        minutes = Math.min(minutes, 59)
    }

    return `${String(days).padStart(2, '0')}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2,'0')}`
}
export default defineComponent({
    name: 'TimeInput',
    components: { BaseInputOutlined },
    props: {
        value: { type: String, required: true },
        label: { type: String, required: false },
        placeholder: { type: String, required: false },
        hideDetails: { type: Boolean, default: true },
        rules: { type: Array, default: () => [] },
        withDays: { type: Boolean, default: false, required: false },
    },
    setup(props, { emit }) {
        const activatorId = computed(() => 'time-input' + '-' + uuidv4().slice(0, 10))
        const menuOpened = ref<boolean>(false)

        const daysList = computed(() =>
            Array.from({ length: 8 }).map((_, i) => (String(i).length === 1 ? '0' + i : String(i)))
        )
        const hoursList = computed(() =>
            Array.from({ length: 24 }).map((_, i) => (String(i).length === 1 ? '0' + i : String(i)))
        )
        const minutesList = computed(() =>
            Array.from({ length: 60 }).map((_, i) => (String(i).length === 1 ? '0' + i : String(i)))
        )
        const selectedDay = computed(() =>
            props.value?.includes(':') ? props.value.split(':')[0] : props.value
        )
        const selectedHour = computed(() =>
            props.value?.includes(':') ? props.value.split(':')[props.withDays ? 1 : 0] : props.value
        )
        const selectedMinute = computed(() =>
            props.value?.includes(':') ? props.value.split(':')[props.withDays ? 2 : 1] : undefined
        )
        const onCreated = async () => {}
        const update = (val: string | undefined) => {
            emit('input', val)
        }
        const validateInput = (e: any) => {
            let val = props.value
            let lastLength
            if (props.withDays) {
                const cleaned = replaceBadInputsWithDays(val)
                if (cleaned !== val) {
                    update(cleaned)
                }
            } else {
                do {
                    lastLength = val.length
                    val = replaceBadInputs(val)
                } while (val.length > 0 && lastLength !== val.length)
                update(val)
            }
        }
        const selectTime = (type: 'day' | 'hour' | 'minute', val: string) => {
            let newVal
            if (props.withDays) {
                let days, hours, minutes
                ;[days, hours, minutes] = props.value.split(':').map(Number)

                if (type === 'day') {
                    days = Math.min(Number(val), 7)
                    if (days === 7 && (minutes > 0 || hours > 0)) {
                        hours = 0
                        minutes = 0
                    }
                }
                if (type === 'hour') {
                    hours = Math.min(Number(val), 23)
                    if (days === 7 && hours > 0) {
                        days = 6
                    }
                }
                if (type === 'minute') {
                    minutes = Math.min(Number(val), 59)
                    if (days === 7 && minutes > 0) {
                        days = 6
                    }
                }
                newVal = `${String(days).padStart(2, '0')}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
            } else {
                if (type === 'hour') {
                    newVal = props.value.includes(':') ? `${val}:${props.value.split(':')[1]}` : val
                }
                if (type === 'minute') {
                    newVal = props.value.length >= 2 ? `${props.value.split(':')[0]}:${val}` : `00:${val}`
                }
            }
            update(newVal)
        }
        const handleInput = (newValue: string) => {
            validateInput(newValue)
        }
        onCreated()
        return {
            replaceBadInputs,
            validateInput,
            selectTime,
            menuOpened,
            activatorId,
            update,
            hoursList,
            minutesList,
            selectedHour,
            selectedMinute,
            daysList,
            selectedDay,
            handleInput,
        }
    },
})
