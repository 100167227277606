<template>
    <div class="content-wrapper">
        <div class="content">
            <div class="titleBox mb-5">
                Настройки бота
            </div>
            <template v-if="needAuth">
                <WhatsappBasisAuth v-if="!oldBot" />
                <WhatsappAuth v-if="oldBot" />
            </template>

            <template v-else>
                <WhatsappBasisSettings
                    v-if="!oldBot"
                    @endSettings="next"
                    @createConnector="createConnector"
                    :createdConnector="createdConnector"
                    @cancel="cancel"
                />
                <WhatsappSettings
                  v-if="oldBot"
                  @endSettings="next"
                  @createConnector="createConnector"
                  :createdConnector="createdConnector"
                  @cancel="cancel"
                />
            </template>
        </div>
    </div>
</template>

<script setup>
import OnBoarding from '@/components/project/onBoarding/OnBoarding.vue'
import WhatsappBasisAuth from '@/components/connectors/whatsappBasis/Auth.vue'
import WhatsapAuth from '@/components/connectors/whatsapp/Auth.vue'
import WhatsappBasisSettings from '@/components/connectors/whatsappBasis/Settings.vue'
import WhatsappSettings from '@/components/connectors/whatsapp/Settings.vue'

import useRouter from '@/hooks/useRouter'
import { computed, defineEmits, getCurrentInstance, onMounted, ref } from 'vue'
import { useProject } from '@root/src/hooks/useProject'
import { CONNECTOR_TYPES } from '../../../vars/general'
const root = getCurrentInstance().proxy
const { project } = useProject()
const router = useRouter()
const emit = defineEmits(['refreshStep'])

const isOnBoardingActive = computed(() => project.value?.isOnBoardingActive)
const isCurrentAddingConnector = sessionStorage.getItem('currentAddingConnector')
const currentAddingConnectorData = {
    type: 'whatsappBasis',
    name: 'WhatsApp',
    maxCount: 1,
    notIncludes: [],
}

if (!isCurrentAddingConnector && isOnBoardingActive.value) {
    sessionStorage.setItem('currentAddingConnector', JSON.stringify(currentAddingConnectorData))
}

const createdConnector = computed(() => {
    const botConnectors = ['whatsapp', 'whatsappBasis']
    const createdConnector = project.value.connectors.find((connector) =>
        botConnectors.includes(connector.connectorType)
    )
    return createdConnector
})

const needAuth = computed(() => {
    const res = createdConnector.value ? false : true
    return res
})

const oldBot = computed(() => {
    return createdConnector.value?.connectorType === CONNECTOR_TYPES.whatsapp
})

const createConnector = async ({ settings, useRedirect, data }) => {
    const loggedAddingConnector = ref(JSON.parse(sessionStorage.getItem('loggedAddingConnector')))
    if (_.isNil(settings)) settings = {}
    if (_.isNil(useRedirect)) useRedirect = true
    settings.isDraft = false
    const connectorId = loggedAddingConnector.value.connectorId
    root.$store.commit('startLoading')
    await root.$store.dispatch('project/updateConnectorSettings', { connectorId, settings })
    root.$store.commit('endLoading')
    if (oldBot.value) {
        emit('refreshStep')
    }
}

const login = async ({ connector }) => {
    try {
        await root.$store.dispatch('project/deleteConnector', connector.connectorId)
    } catch (err) {}

    const res = await root.$store.dispatch('project/addConnector', connector)
    if (res.error) {
        root.$store.dispatch('callNotify', 'Ошибка')
        return
    }
}

const cancel = () => {
    const projectId = project.value.id
    router.router.push(`/projects`)
}

const next = () => {
    emit('refreshStep')
}

root.$on('endSettings', next)
root.$on('login', login)
root.$on('createConnector', createConnector)
root.$on('cancel', cancel)
</script>
<style lang="sass" scoped>
.content-wrapper
    margin-top: 40px
    .content
        align-items: flex-start
        justify-content: center
        display: flex
        flex-direction: column
</style>