<template>
    <div class="content-wrapper">
        <div class="content">
            <component :is="activeComponent" v-if="activeComponent" :currentPendingConnector="currentPendingConnector" class="mt-12"></component>
            <span class="support-info">Если у вас возникли вопросы - напишите в нашу техподдержку: <a href="https://t.me/dataheroes_bot">https://t.me/dataheroes_bot</a> </span>
        </div>
    </div>
</template>

<script>
import YClientsAuth from '@/components/connectors/yClients/Auth.vue'
import YClientsSimpleAuth from '@/components/connectors/yClients/AuthSimple.vue'
import UniverseSoftAuth from '@/components/connectors/universeSoft/Auth.vue'
import SycretAuth from '@/components/connectors/sycret/Auth.vue'
import SonlineAuth from '@/components/connectors/sonline/Auth.vue'
import ArnicaAuth from '@/components/connectors/arnicaV2/ArnicaV2.vue'
import AltegioAuth from '@/components/connectors/altegio/Auth.vue'
import ProfSalonBatAuth from '@/components/connectors/profSalonBat/Auth.vue'
import ArchimedAuth from '@/components/connectors/archimed/Auth.vue'
import dentalProAuth from '@/components/connectors/dentalPro/Auth.vue'
import KlientixAuth from '@/components/connectors/klientix/Auth.vue'
import Cloud1CAuth from '@/components/connectors/cloud1C/Auth.vue'
import OnBoarding from '@/components/project/onBoarding/OnBoarding.vue'
import DikidiAuth from '@/components/connectors/dikidi/AuthSimple.vue'
import OtherConnector from './OtherConnector'
import { CONNECTOR_TYPES } from '@/vars/general'
import { computed, getCurrentInstance, onBeforeMount, onMounted, ref, watchEffect } from 'vue'
import useRouter from '@/hooks/useRouter'
import { useProject } from '@root/src/hooks/useProject'
import api from '../../../api/api'
import { getInstance } from '@/auth/authWrapper'
import { createPendingConnectorRecord, savePendingComment } from '@/api/pendingConnectors'
import { MESSAGING_CONNECTOR_TYPES, PENDING_CONNECTOR_TYPES } from '../../../vars/general'
export default {
    name: 'AuthorizationInCRMSystem',

    components: {
        OnBoarding,
        YClientsAuth,
        YClientsSimpleAuth,
        UniverseSoftAuth,
        SycretAuth,
        SonlineAuth,
        ArnicaAuth,
        AltegioAuth,
        ProfSalonBatAuth,
        ArchimedAuth,
        dentalProAuth,
        KlientixAuth,
        Cloud1CAuth,
        DikidiAuth,
        OtherConnector
    },

    setup(props, { emit }) {
        const root = getCurrentInstance().proxy
        let currentAddingConnector = sessionStorage.getItem('currentAddingConnector')
        const router = useRouter()
        const store = root.$store
        const { project } = useProject()
        const currentPendingConnector = ref();

        const views = [
            { connectorType: CONNECTOR_TYPES.yClients, component: YClientsSimpleAuth },
            { connectorType: CONNECTOR_TYPES.universeSoft, component: UniverseSoftAuth },
            { connectorType: CONNECTOR_TYPES.sycret, component: SycretAuth },
            { connectorType: CONNECTOR_TYPES.profSalon, component: ProfSalonBatAuth },
            { connectorType: CONNECTOR_TYPES.profSalonBat, component: ProfSalonBatAuth },
            { connectorType: CONNECTOR_TYPES.profSalonBatV2, component: ProfSalonBatAuth },
            { connectorType: CONNECTOR_TYPES.sonline, component: SonlineAuth },
            { connectorType: CONNECTOR_TYPES.arnicaV2, component: ArnicaAuth },
            { connectorType: CONNECTOR_TYPES.altegio, component: AltegioAuth },
            { connectorType: CONNECTOR_TYPES.archimed, component: ArchimedAuth },
            { connectorType: CONNECTOR_TYPES.cloud1C, component: Cloud1CAuth },
            { connectorType: CONNECTOR_TYPES.dentalPro, component: dentalProAuth },
            { connectorType: CONNECTOR_TYPES.klientix, component: KlientixAuth },
            { connectorType: CONNECTOR_TYPES.dikidi, component: DikidiAuth },
            { connectorType: 'other', component: OtherConnector },
        ]

        const currentAddingConnectorType = () => {
            return currentAddingConnector.replace(/"/g, '')
        }

        const activeComponent = computed(() => {
            const currentConnectorType = currentAddingConnectorType()
            return views.find((el) => el.connectorType === currentConnectorType)?.component
        })

        const next = async () => {
            const connType = project.value.connectors[0].connectorType === CONNECTOR_TYPES.yClients ||
                project.value.connectors[0].connectorType === CONNECTOR_TYPES.dikidi ?
                'whatsappBasis' :
                'whatsapp'
            store.commit('project/updateCurrentAddingConnector', connType)
            if (currentPendingConnector.value) {
                await savePendingComment({id: currentPendingConnector.value.connectorId, comment: ''})
            }

            localStorage.removeItem('onboardingSelectedCrm')
            emit('refreshStep')
        }

        const goBack = () => {
            localStorage.removeItem('onboardingSelectedCrm')
            sessionStorage.removeItem('currentAddingConnector')
            emit('refreshStep')
        }

        const login = async () => {
            const loggedAddingConnector = ref(JSON.parse(sessionStorage.getItem('loggedAddingConnector')))
            const res = await root.$store.dispatch('project/addConnector', loggedAddingConnector.value)
            if (res.error) {
                root.$store.dispatch('callNotify', 'Ошибка')
                return
            }
            const connectorId = loggedAddingConnector.value.connectorId
            const auth = getInstance()
            if (PENDING_CONNECTOR_TYPES.includes(loggedAddingConnector.value.connectorType)) {
                await createPendingConnectorRecord({
                    userName: auth.user.name,
                    userEmail: auth.user.email,
                    projectId: project.value.id,
                    projectName: project.value.name,
                    connectorId: connectorId,
                    userId: auth.user.sub,
                    connectorType: loggedAddingConnector.value.connectorType,
                    webhookKey: null,
                    stage: 1,
                })
            }

            await root.$store.dispatch('project/updateConnectorSettings', {
                connectorId,
                settings: {
                    isDraft: false,
                },
            })
            next()
        }

        root.$on('login', login)
        root.$on('next', next)
        root.$on('goBackToCrm', goBack)
        onBeforeMount( async () => {

            const pendingConnectors = await api.project.getPendingConnectorsByProject({ projectId: project.value.id })
            currentPendingConnector.value = pendingConnectors.data[0]
          }
        )
        return {
            activeComponent,
            currentPendingConnector,
            goBack
        }
    },
}
</script>
<style lang="sass" scoped>
.content-wrapper
    margin-top: 40px
    .content
        align-items: center
        justify-content: center
        display: flex
        flex-direction: column
        max-width: 81.25%
        .support-info
            margin-top: 20px
            font-size: 18px
            font-weight: 600
    .to-crm-select-btn
        align-self: flex-start

</style>