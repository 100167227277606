<template>
    <v-form @submit.prevent="() => {}" ref="form">
        <v-row>
            <v-checkbox
                v-model="value.sendToStaff"
                label="Отправлять Мастеру"
                class="ml-3 mb-n4"
            ></v-checkbox>
        </v-row>
        <v-row
            align="center"
            style="flex-wrap: nowrap;"
            :class="availableAdditionalSteps.length === 0 ? 'mb-2' : ''"
        >
            <CustomActivatorSelect
                class="add-select"
                v-model="currentSetting"
                @input="addSettings"
                :items="availableSettingsList"
                :multiple="false"
                style="width: max-content"
            >
                <template v-slot:activator="{ selected, _id }">
                    <button class="add-btn" type="button" :id="_id" :value="selected ? selected.name : ''">
                        <v-icon color="main">mdi-plus</v-icon>
                    </button>
                </template>
            </CustomActivatorSelect>
            <v-tabs
                v-if="availableAdditionalSteps.length > 0"
                :key="'slider' + sliderKey"
                :active-class="'active-tab'"
                v-model="activeTabNum"
                class="my-3 ml-4"
            >
                <v-tabs-slider color="main"></v-tabs-slider>
                <v-tab v-for="tab in availableAdditionalSteps" :key="tab.id">
                    <span class="tab__name">{{ tab.title }}</span>
                </v-tab>
            </v-tabs>
            <span class="ml-2" v-else>Добавьте настройки</span>
            <div class="personal-buttons mr-6" @click.stop="() => {}">
                <v-btn
                    class="outlined-btn activation-step-button"
                    outlined
                    small
                    v-show="availableAdditionalSteps.length > 0"
                    @click.stop="clearSettings(currentStep)"
                    >Очистить</v-btn
                >
                <v-btn
                    class="delete-button outlined-btn activation-step-button ml-2"
                    outlined
                    small
                    v-show="availableAdditionalSteps.length > 0"
                    @click.stop="deleteSetting(currentStep)"
                    >Удалить</v-btn
                >
            </div>
        </v-row>
        <v-row>
            <component
                v-if="currentStep"
                :key="currentStep.id + '-' + triggerValueKey"
                :is="currentStep.contentComponent"
                v-model="triggerValue"
                :mode="mode"
                :ref="currentStep.ref"
                @update-trigger="updateTrigger"
            />
        </v-row>
    </v-form>
</template>
<script>
import { defineComponent, ref, computed, watch, getCurrentInstance, onMounted } from 'vue'
import useRouter from '../../../../../hooks/useRouter'
import * as _ from 'lodash'
import BaseInputOutlined from '../../../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../../../inputs/CustomActivatorSelect.vue'
import VisitsStep from './VisitsStep.vue'
import StaffStep from './StaffStep.vue'
import InstructionsStep from './InstructionsStep.vue'
import { CHANGE_BOT_TRIGGER_TYPES } from '@root/src/vars/GCB2'

export default defineComponent({
    name: 'PersonalStep',
    components: { BaseInputOutlined, CustomActivatorSelect },
    props: {
        value: { type: Object, required: true },
        mode: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        CHANGE_BOT_TRIGGER_TYPES,
    }),
    setup(props, { emit }) {
        const root = getCurrentInstance().proxy
        const { route } = useRouter(root)
        const project = computed(() => root.$store.getters.projectById(route.value.params.id))
        const triggerValueKey = ref(0)
        const sliderKey = ref(0)
        const stepsIndexes = ref([])
        const visitsKey = ref(null)
        const staffKey = ref(null)
        const servicesKey = ref(null)
        const activeTabNum = ref(0)
        const additionalSteps = computed(() => {
            return [
                {
                    id: 1,
                    title: 'Посещения',
                    contentComponent: VisitsStep,
                    ref: visitsKey,
                },
                {
                    id: 2,
                    title: 'Мастера',
                    contentComponent: StaffStep,
                    ref: staffKey,
                },
                {
                    id: 3,
                    title: 'Услуги',
                    contentComponent: InstructionsStep,
                    ref: servicesKey,
                },
            ]
        })
        const currentStep = computed(() => {
            return availableAdditionalSteps.value[activeTabNum.value] || null
        })
        const expandDisabled = computed(() => {
            return props.mode === CHANGE_BOT_TRIGGER_TYPES.edit
        })
        const settingsList = ref([
            { id: 1, name: 'Каким клиентам отправлять' },
            { id: 2, name: 'Выборка по мастерам' },
            { id: 3, name: 'Выборка по услугам' },
        ])
        const triggerValue = ref(props.value)
        const currentSetting = ref()
        const clearSettings = step => {
            switch (step.id) {
                case 1:
                    triggerValue.value.visitsCountCondition.min = ''
                    triggerValue.value.visitsCountCondition.max = ''
                    triggerValue.value.delay = ''
                    visitsKey.value?.clear()
                    break
                case 2:
                    triggerValue.value.staffIds = []
                    staffKey.value?.clear()
                    break
                case 3:
                    triggerValue.value.instructions = []
                    servicesKey.value?.clear()
                default:
                    break
            }
        }
        const includedSettingsList = ref([])
        const includedSettingsListIds = computed(() => {
            return includedSettingsList.value
                .map(el => el.id)
                .sort(function(a, b) {
                    return a - b
                })
        })
        const selectedSetting = computed(() => {
            return settingsList.value.find(el => el.id === currentSetting.value)
        })
        const availableSettingsList = computed(() => {
            const includedSettingsIds = new Set(includedSettingsList.value.map(ex => ex.id))

            return settingsList.value.map(el => ({
                ...el,
                disabled: includedSettingsIds.has(el.id),
            }))
        })
        const availableAdditionalSteps = computed(() => {
            return additionalSteps.value.filter(el => {
                return includedSettingsListIds.value.includes(el.id)
            })
        })

        const addSettings = () => {
            includedSettingsList.value.push({
                id: selectedSetting.value.id,
                name: selectedSetting.value.name,
            })
            includedSettingsList.value = includedSettingsList.value.sort((a, b) => a.id - b.id)
            activeTabNum.value = includedSettingsList.value.findIndex(
                el => el.id === selectedSetting.value.id
            )
            sliderKey.value++
            currentSetting.value = ''
        }
        const deleteSetting = step => {
            includedSettingsList.value = includedSettingsList.value
                .filter(el => el.id !== step.id)
                .sort((a, b) => a.id - b.id)
            activeTabNum.value = 0
            clearSettings(step)
            sliderKey.value++
        }
        const init = () => {
            stepsIndexes.value = availableAdditionalSteps.value.map((el, i) => i)
            if (
                props.value?.delay ||
                props.value?.visitsCountCondition?.min ||
                props.value?.visitsCountCondition?.max
            ) {
                includedSettingsList.value.push({ id: 1, name: 'Каким клиентам отправлять' })
            }
            if (props.value?.staffIds?.length > 0) {
                includedSettingsList.value.push({ id: 2, name: 'Выборка по мастерам' })
            }
            if (props.value?.instructions?.length > 0) {
                includedSettingsList.value.push({ id: 3, name: 'Выборка по услугам' })
            }
        }
        const updateTrigger = v => {
            emit('update-trigger', v)
        }
        watch(
            () => availableAdditionalSteps.value,
            v => {
                stepsIndexes.value = v.map((el, i) => i)
            },
            { deep: true, immediate: true }
        )
        watch(
            () => triggerValue.value,
            v => {
                emit('update-trigger', v)
            },
            { deep: true, immediate: true }
        )
        watch(
            () => props.value,
            v => {
                triggerValue.value = props.value
            },
            { deep: true, immediate: true }
        )

        onMounted(() => {
            init()
        })

        return {
            clearSettings,
            addSettings,
            deleteSetting,
            stepsIndexes,
            expandDisabled,
            additionalSteps,
            settingsList,
            currentSetting,
            availableSettingsList,
            selectedSetting,
            includedSettingsList,
            availableAdditionalSteps,
            includedSettingsListIds,
            triggerValue,
            triggerValueKey,
            visitsKey,
            staffKey,
            servicesKey,
            updateTrigger,
            activeTabNum,
            currentStep,
            sliderKey,
        }
    },
})
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
::v-deep .v-input__append-outer
    margin-top: 9px
::v-deep .v-input__prepend-inner
    margin-top: 9px
.message-parts-slider-title
    color: $gray
    font-size: 12px
    line-height: 16px
    font-weight: $font-weight-medium
    letter-spacing: 1.07px
.slider-part
    display: flex
    align-items: center
    cursor: pointer
    width: 249px
    height: 40px
    border-left: 4px solid $gray-2
    &.active
        border-left: 4px solid $main
    &.disabled
        opacity: 0.5
        pointer-events: none
.slider-part-text
    margin-left: 8px
    color: $gray
    &.active
        color: $text-color
.add-select
    margin: 12px
.personal-buttons
    display: flex
.delete-button
    border-color: $dangeour
    color: $dangeour !important
</style>
