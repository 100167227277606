import { render, staticRenderFns } from "./CardsView.vue?vue&type=template&id=b885dfa6&scoped=true"
import script from "./CardsView.vue?vue&type=script&lang=js"
export * from "./CardsView.vue?vue&type=script&lang=js"
import style0 from "./CardsView.vue?vue&type=style&index=0&id=b885dfa6&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b885dfa6",
  null
  
)

export default component.exports