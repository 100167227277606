
import Vue from 'vue'
import { Connector, Project } from '@/types/main'

SpinnerLoader
import _ from 'lodash'
import TriggerCard from '@/components/chisai/GCB2/bot/TriggerCard/TriggerCard.vue'
import { CONNECTOR_TYPES } from '@/vars/general'
import { checkRole } from '@/helpers/roles'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import api from '@/api/api'
import { BOT_TRIGGER_CARDS } from '../../../vars/GCB2'
import { canActivateTrigger } from '../../../helpers/whatsapp'
import DemoSubView from './DemoSubView.vue'

export default Vue.extend({
    components: { SpinnerLoader, TriggerCard, DemoSubView},

    data: () => ({
        triggers: {} as any,
        customTriggers: {} as any,
        customTriggersPreview: [] as any,
        allTriggers: {} as any,
        loading: true,
        cardsUpdatedTimestamp: 0,
        selectedTags: [],
    }),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        botConnector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.whatsappBasis)
        },
        activeTriggersIds(): string[] {
            return Object.keys(this.allTriggers)
                .map(id => ({ id, ...this.allTriggers[id as any] }))
                .filter(trigger => trigger.status === 'on')
                .map(trigger => trigger.id)
        },
        cards(): any[] {
            return Object.keys(this.allTriggers)!.map((key: any) => ({
                id: key,
                title: this.allTriggers[key]?.name || _.get(BOT_TRIGGER_CARDS, `${key}.title`),
                description:
                    this.allTriggers[key]?.description || _.get(BOT_TRIGGER_CARDS, `${key}.description`),
                isRunning: this.allTriggers[key].status === 'on' ? true : false,
                triggerData: this.allTriggers[key]
            }))
        },
        activeCards(): any[] {
            return this.cards.filter(el => el.isRunning)
        },
        inactiveCards(): any[] {
            return this.cards.filter(el => !el.isRunning)
        },
    },

    methods: {
        checkRole,
        goToCreate(status: 'on' | 'off') {
            function randomId() {
                const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
                let result = '';

                for (let i = 0; i < 4; i++) {
                    const randomIndex = Math.floor(Math.random() * letters.length);
                    result += letters[randomIndex];
                }
                return 'custom_' + result;
                
            }
            sessionStorage.setItem('triggerStatusToCreate', status)
            const cardId = randomId()
            this.$router.push(`${this.$router.currentRoute.path}/botTrigger?mode=create&id=${cardId}`)
        },
        async changeIsRunning({ id, value }: { id: string; value: boolean }) {
            if (!canActivateTrigger(id, this.activeTriggersIds).canActivate) {
                return
            }
            let updatedTrigger = {}
            let updatedCustomTrigger = {}
            
            if(id.includes('custom_')) {
                updatedCustomTrigger = {
                    [id]: Object.assign({}, this.customTriggers[id as any], { status: value ? 'on' : 'off' }),
                }
            } else {
                updatedTrigger = {
                    [id]: Object.assign({}, this.triggers[id as any], { status: value ? 'on' : 'off' }),
                }
            }
            this.triggers = Object.assign({}, this.triggers, updatedTrigger)
            this.customTriggers = Object.assign({}, this.customTriggers, updatedCustomTrigger)
            this.allTriggers = Object.assign({}, this.triggers, this.customTriggers)
            const customTriggersToSend: any[] = Object.values(this.customTriggers)
            const filteredCustomTriggersToSend = customTriggersToSend.filter(el => el.triggerId !== id)
            const customTriggerToSend = Object.values(updatedCustomTrigger)
            let error = null
            if (id.includes('custom_')) {
                const { data, error: e } = await api.networkFixer.updateApp({
                    connectorId: this.botConnector?.connectorId!,
                    data: {
                        customTriggers: [...filteredCustomTriggersToSend, ...customTriggerToSend],
                    },
                })
                error = e
            } else {
                const { data, error: e } = await api.networkFixer.updateApp({
                    connectorId: this.botConnector?.connectorId!,
                    data: {
                        triggers: updatedTrigger,
                    },
                })
                error = e
            }

            if (error) {
                this.$store.dispatch('callNotify', 'Произошла ошибка')
                this.$store.commit('endLoading')
                return
            }
        },

        async deleteTrigger(id: string) {
            this.loading = true
            const customTriggersToSend: any[] = Object.values(this.customTriggers)
            const filteredCustomTriggersToSend = customTriggersToSend.filter(el => el.triggerId !== id)
            this.customTriggers = filteredCustomTriggersToSend.reduce((acc, el) => {
                acc[el.triggerId] = el
                return acc
            }, {})
            this.allTriggers = Object.assign({}, this.triggers, this.customTriggers)
            const { data, error } = await api.networkFixer.updateApp({
                connectorId: this.botConnector?.connectorId!,
                data: {
                    customTriggers: [...filteredCustomTriggersToSend],
                },
            });
            [this.triggers, this.customTriggersPreview] = await api.networkFixer
                .getApp({ connectorId: this.botConnector!.connectorId })
                .then(res => [res.data!.data.triggers, res.data!.data.customTriggers])
            this.customTriggers = this.customTriggersPreview?.reduce((acc, el) => {
                acc[el.triggerId] = el
                return acc
            }, {})
            this.allTriggers = Object.assign({}, this.triggers, this.customTriggers)
            if (error) {
                this.$store.dispatch('callNotify', 'Произошла ошибка')
                this.$store.commit('endLoading')
                return
            }
            this.loading = false
        },

        async setTriggers() {
            this.loading = true;
            [this.triggers, this.customTriggersPreview] = await api.networkFixer
                .getApp({ connectorId: this.botConnector!.connectorId })
                .then(res => [res.data!.data.triggers, res.data!.data.customTriggers])
            this.customTriggers = this.customTriggersPreview?.reduce((acc, el) => {
                acc[el.triggerId] = el
                return acc
            }, {})
            this.allTriggers = Object.assign({}, this.triggers, this.customTriggers)
            this.loading = false
        },
    },

    mounted() {
        this.setTriggers()
    },
})
