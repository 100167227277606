<template>
    <div>
        <div v-if="infoStep" class="auth">
            <h2 class="dashboard">Введите ваши логин и пароль от системы Арника</h2>
            <h2 class="dashboard"><b>(Важно! Для интеграции необходимо использовать аккаунт с правами владельца)</b></h2>
            <v-form class="form-block pa-10" v-model="valid">
                <TextField label="Логин Арника" prependInnerIcon='mdi-account' v-model="credentials.login" required />
                <TextField
                  prependInnerIcon='mdi-lock'
                  label="Пароль Арника"
                  v-model="credentials.password"
                  required
                  type="password"
                />
                <div class="btn-wrap">
                    <v-btn
                      color="white"
                      class="black--text to-crm-select-btn"
                      @click="$parent.$emit('goBackToCrm')"
                    >Назад к выбору CRM</v-btn>
                    <v-btn
                      color="main"
                      class="white--text"
                      @click="toggleinfo"
                      :disabled="nextBtnDisabled"
                    >Далее</v-btn>
                </div>
            </v-form>
        </div>
        <div v-else class="auth">
            <h2>Вам нужно написать на почту <a href="mailto:support@arnica.pro">support@arnica.pro</a> письмо с той почты, на которую зарегистрирован ваш аккаунт арника с текстом:</h2>
            <h2>"Добрый день! Прошу предоставить ключ "Partner_token", который используется для интеграции в салоне &lt;Название салона&gt;"</h2>
            <h2>Если ответа не будет более суток, то можно написать в чат поддержки о том, что вы запрашивали ключ, но вам не ответили.</h2>
            <h2>Вставьте полученный токен в поле ниже и мы сможем завершить процесс интеграции</h2>
            <div class="form-block pa-10">
            <v-form v-model="valid">
                <v-row class="ma-0">
                    <TextField
                        color="main"
                        filled
                        v-model="credentials.partnerToken"
                        label="partner token"
                        required
                    ></TextField>
                </v-row>
                <p v-if="validLogin === false" class="error--text">Не верные данные</p>
                <div class="btn-wrap">
                    <v-btn
                      color="white"
                      class="black--text to-crm-select-btn"
                      @click="toggleinfo"
                    >Назад</v-btn>
                    <v-btn
                      color="main"
                      class="white--text"
                      @click="save"
                      :disabled="saveBtnDisabled"
                      :loading="loading"
                    >Далее</v-btn
                    >
                </div>

            </v-form>
        </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import api from '@/api/api'
import { CONNECTOR_TYPES } from '@/vars/general'
import { saveCredentials } from '@api/connectors/arnicaV2'
import { ref } from 'vue'
export default {
    components: { TextField, HintIcon },
    data: () => ({
        validLogin: null,
        valid: false,
        loading: false,
        infoStep: true,
        credentials: {
            login: '',
            password: '',
            partnerToken: '',
        },
        connector: null,
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),

        saveBtnDisabled() {
            return !this.credentials.partnerToken
        },
        nextBtnDisabled() {
            return !this.credentials.login || !this.credentials.password
        },
    },
    methods: {
        async toggleinfo() {
            this.infoStep = !this.infoStep
        },

        async save() {
            this.loading = true

            const projectId = this.$router.currentRoute.params.id
            const pendingConnectors = await api.project.getPendingConnectorsByProject({ projectId})
            const pendingConnector = pendingConnectors.data[0];
            const connector = pendingConnector ?? await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.arnicaV2,
            })
            await saveCredentials(connector, this.credentials)

            this.$store.commit('project/setLoggedAddingConnector', connector)
            if (pendingConnector) {
                this.$parent.$emit('next')
            } else {
                this.$parent.$emit('login')
            }
            this.loading = false
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: flex-start
    align-items: center
    flex-direction: column
    gap: 12px
    max-width: 1200px
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    max-width: 460px
.btn-wrap
    margin-top: 12px
    display: flex
    gap: 20px
</style>
