<template>
    <div class="auth">
        <h2>Вам необходимо обратиться к менеджеру Sycret и попросить его предоставить Вам Api-ключ. Когда вам пришлют API-ключ, вставьте его в поле ниже и мы сможем совершить интеграцию.</h2>
        <div class="form-block pa-10">
            <v-form v-model="valid">
                <v-row class="ma-0">
                    <TextField
                        type="password"
                        color="main"
                        filled
                        v-model="apiKey"
                        label="Ключ API"
                        required
                    ></TextField>
                </v-row>
                <p v-if="validLogin === false" class="error--text">Не верные данные</p>
                <div class="btn-wrap">
                    <v-btn
                      color="white"
                      class="black--text to-crm-select-btn"
                      @click="$parent.$emit('goBackToCrm')"
                    >Назад к выбору CRM</v-btn>
                    <v-btn
                      color="main"
                      class="white--text"
                      @click="login"
                      :disabled="btnDisabled"
                      :loading="loading"
                    >Войти</v-btn>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import cfg from '../../../../config'
import { login } from '@api/connectors/sycrect'
import { CONNECTOR_TYPES } from '@/vars/general'
export default {
    components: { TextField, HintIcon },
    data: () => ({
        validLogin: null,
        valid: false,
        apiKey: '',
        loading: false,
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),

        btnDisabled() {
            if (!this.apiKey) {
                return true
            }
            return false
        },
    },
    methods: {
        async login() {
            this.loading = true
            const credentials = {
                apiKey: this.apiKey,
            }
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.sycret,
            })
            const isLogin = await login(connector, credentials)

            if (isLogin) {
                this.$store.commit('project/setLoggedAddingConnector', connector)
                this.$parent.$emit('login')
            }
            this.validLogin = isLogin
            this.loading = false
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: flex-start
    align-items: center
    flex-direction: column
    gap: 12px
    max-width: 1200px
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    max-width: 460px
.btn-wrap
    margin-top: 12px
    display: flex
    gap: 20px
</style>
