import { apiCall, http } from '../http'
import { Connector } from '@t/main'
import cfg from '@root/config'
import { getError } from '@/utils'
import { getInstance } from '../../auth'
interface LoginParams {
    userToken: string
    partnerToken: string
    connectorId: string
}
export async function login({ userToken, partnerToken, connectorId }: LoginParams) {
    const url = `arnicaV2/login`
    const res = apiCall<any>(() =>
        http.post(url.toString(), { json: { userToken, partnerToken, connectorId } })
    )
    return res
}

export async function saveCredentials(
    connector: Connector,
    credentials: { login: string; password: string }
) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/arnicaV2/saveCredentials`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            ...connector,
            ...credentials,
        }),
    }
    const res = await fetch(url, options)
    if (res.status > 400) {
        return { data: null, error: getError(res) }
    } else {
        return { data: await res.json(), error: null }
    }
}
