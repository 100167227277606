<template>
    <div class="content-wrapper">
        <Tour :steps="steps"></Tour>
        <div class="content">
            <v-dialog v-model="clientFormOpened" width="600">
                <ClientForm reason="projectCreate" @answers-saved="onClientFormSaved"></ClientForm>
            </v-dialog>
            <!--Диалоговые окна-->
            <v-dialog v-model="isCreateProjectDialog" width="500">
                <CreateProjectDialog> </CreateProjectDialog>
            </v-dialog>
            <v-dialog v-model="isAddUserDialog" width="500">
                <AddUserDialog :projectId="currentProjectId"> </AddUserDialog>
            </v-dialog>
            <ChangeProjectImageDialog :projectId="currentProjectId"> </ChangeProjectImageDialog>
            <!---->
            <v-row class="ma-0 mb-6" align="center">
                <h1 class="display-1">Ваши проекты</h1>
                <HintIcon hintId="projects" class="ml-2" id="tour-1" @click.native="finishTour"></HintIcon>
            </v-row>
            <div class="btns-block ma-0">
                <v-row class="ma-0">
                    <v-btn
                        class="mr-2 thin-btn"
                        outlined
                        v-if="currentProjectView === 'list' && hasOneOwnedProject"
                        :disabled="disableDeleteBtn"
                        @click="deleteManyProjects"
                        ><v-icon class="pr-1">mdi-close-circle</v-icon>Удалить</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        elevation="3"
                        color="main"
                        class="white--text btn-w-icon"
                        @click="openCreateProjectDialog"
                    >
                        <v-icon class="mr-5px"> mdi-plus </v-icon>Создать проект</v-btn
                    >
                    <ChangeViewBtn
                        v-model="currentProjectView"
                        cacheKey="currentProjectView"
                        class="pa-0 ml-4"
                    >
                    </ChangeViewBtn>
                </v-row>
            </div>
            <div>
                <v-scroll-x-transition :hide-on-leave="true">
                    <v-row v-if="isProjectsLoading" justify="center" align="center">
                        <v-progress-circular class="mt-16" indeterminate color="main"></v-progress-circular>
                    </v-row>
                    <div v-else>
                        <ListViewTable
                            :projects="projects"
                            v-if="currentProjectView === 'list'"
                            class="mt-6"
                        ></ListViewTable>
                        <CardsView
                            :projects="projects"
                            v-if="currentProjectView === 'cards'"
                            class="mt-4"
                        ></CardsView>
                    </div>
                </v-scroll-x-transition>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Tour from '@/components/Tour.vue'
import { mapState } from 'vuex'

import HintIcon from '@/components/HintIcon'
import ChangeViewBtn from '@/components/buttons/ChangeViewBtn'
import CardsView from '@/components/project/CardsView'
import ListViewTable from '@/components/project/ListViewTable'
import AddUserDialog from '@/components/project/dialogs/AddUserDialog'
import ChangeProjectImageDialog from '@/components/project/dialogs/ChangeProjectImageDialog'
import CreateProjectDialog from '@/components/project/dialogs/CreateProjectDialog'
import { checkAccess } from '@/utils'
import { mapStores } from 'pinia'
import api from '../api/api'
import ClientForm from '../components/clientForm/ClientForm.vue'
import { useConfirmStore } from '../store/stores/confirm'
export default {
    name: 'Projects',
    components: {
        ListViewTable,
        CardsView,
        CreateProjectDialog,
        AddUserDialog,
        ChangeViewBtn,
        ChangeProjectImageDialog,
        HintIcon,
        Tour,
        ClientForm,
        ClientForm,
    },
    data: () => ({
        currentProjectId: null,
        currentProjectView: null,
        clientFormExists: null,
        clientFormOpened: false,
        steps: [
            {
                target: '#tour-1',
                header: {
                    title: 'Get Started',
                },
                content: `Рады видеть вас в числе пользователей сервиса DataHeroes. Если по ходу использования приложения у вас будут возникать вопросы, вы можете обратиться к подсказкам. Они выглядят как знак вопроса, за которым кроется нужная информация`,
                params: {
                    highlight: true,
                    placement: 'right',
                    enableScrolling: false,
                    modifiers: [
                        //from https://popper.js.org/
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 50],
                            },
                        },
                        {
                            name: 'preventOverflow',
                            options: {
                                mainAxis: true,
                                altAxis: true,
                            },
                        },
                    ],
                },
            },
        ],
    }),
    computed: {
        ...mapState(['projects', 'roles']),
        ...mapState('project', ['selectedProjects', 'isProjectsLoading']),
        ...mapStores(useConfirmStore),
        isCreateProjectDialog: {
            get() {
                return this.$store.state.project.isCreateProjectDialog
            },
            set(value) {
                this.$store.commit('project/updateIsCreateProjectDialog', value)
            },
        },
        isAddUserDialog: {
            get() {
                return this.$store.state.project.isAddUserDialog
            },
            set(value) {
                this.$store.commit('project/updateIsAddUserDialog', value)
            },
        },
        disableDeleteBtn() {
            return this.selectedProjects.length === 0 || this.currentProjectView === 'cards'
        },
        hasOneOwnedProject() {
            return this.projects.some(el => this.checkAccess(el.id, 'owner'))
        },
    },
    methods: {
        checkAccess,
        onClientFormSaved() {
            this.clientFormExists = { value: true }
            this.clientFormOpened = false
            this.openCreateProjectDialog()
        },
        openCreateProjectDialog() {
            if (this.clientFormExists?.value === false) {
                this.clientFormOpened = true
                return
            }
            this.$store.commit('project/updateIsCreateProjectDialog', true)
        },

        openAddUserDialog(projectId) {
            this.currentProjectId = projectId
            this.$store.commit('project/updateIsAddUserDialog', true)
        },
        changeProjectImage(projectId) {
            const fileIsPngOrJpg = file => {
                if (!file) {
                    return false
                }
                const type = file.type.split('/')[1]
                return type === 'jpg' || type === 'png' || type === 'jpeg'
            }
            const input = document.createElement('input')
            input.type = 'file'
            input.click()
            input.onchange = async () => {
                const file = input.files[0]
                if (!fileIsPngOrJpg(file)) {
                    this.$store.commit('setError', 'Файл должен быть в формате jpg, jpeg или png', {
                        root: true,
                    })
                    return
                }
                await this.$store.dispatch('project/changeProjectImage', {
                    projectId: projectId,
                    file: file,
                })
            }
        },
        deleteProject(projectId) {
            this.confirmStore.confirm({
                text: `Проект будет удален.`,
                onConfirm: async () => {
                    await this.$store.dispatch('project/deleteProject', projectId)
                },
            })
        },
        renameProject(projectId) {
            this.$store.dispatch('project/callRenameProjectDialog', projectId)
        },
        async deleteManyProjects() {
            this.confirmStore.confirm({
                text: `Проекты будут удалены.`,
                onConfirm: async () => {
                    const ids = this.selectedProjects.map(proj => proj.id)
                    for (const projectId of ids) {
                        await this.$store.dispatch('project/deleteProject', projectId)
                        this.$store.commit('project/updateSelectedProjects', [
                            ...this.selectedProjects.filter(el => el.id !== projectId),
                        ])
                    }
                },
            })
        },
        startTour() {
            const getStartedTourIsFinished = localStorage.getItem('getStartedTourIsFinished')
            if (!getStartedTourIsFinished) {
                this.$tours['getStarted'].start()
            }
        },
        finishTour() {
            this.$tours['getStarted'].stop()
            localStorage.setItem('getStartedTourIsFinished', true)
        },
    },

    async created() {
        this.currentProjectView = localStorage.getItem('currentProjectView') ?? 'cards'
        this.$on('deleteProject', this.deleteProject)
        this.$on('addUser', this.openAddUserDialog)
        this.$on('renameProject', this.renameProject)
        this.$on('changeProjectImage', this.changeProjectImage)
        this.clientFormExists = await api.clientForm.getClientFormExists().then(res => res.data)
    },
    mounted() {
        this.startTour()
    },
}
</script>
<style lang="sass" scoped>
::v-deep tr
    cursor: pointer

::v-deep.content
    .v-select
        flex-shrink: 10
        .v-select__selection
            max-width: unset
    .select-label
        font-size: 12px
        line-height: 16px
        padding-right: 16px
        color: #2A3B5199
        flex-shrink: 0
    .v-pagination
        margin-left: 14px
        button
            width: 36px
            height: 36px
            border-radius: 100%
            box-shadow: none
            margin: 0 2px
            background-color: transparent
            color: #2A3B51DE
            font-size: 12px
            line-height: 16px
            &:hover
                background-color: #F4F4F4
            &:active
                background-color: #C0C0C0
    .v-select__slot
        font-size: 14px
        line-height: 20px
        color: #2A3B51DE
</style>
