
import { computed, defineComponent, ref } from 'vue'
import { useProject } from '../../../../../hooks/useProject'
import { useBillingStore } from '../../../../../store/stores/billing'
import { useConfirmStore } from '@/store/stores/confirm'

export default defineComponent({
    props: {},
    setup(props, { emit }) {
        const { project } = useProject()
        const billingStore = useBillingStore()
        const confirmStore = useConfirmStore()
        const unsubscribeLoading = ref()
        const currentTariffData = computed(() => billingStore.getCurrentTariff(project.value.id))
        const selectedPromoData = computed(() => billingStore.activePromocode)
        const selectedTariff = computed(() => billingStore.getTariffById(billingStore.selectedTariffId!)!)
        const selectedTariffPriceDescription = computed(() => {
            if (billingStore.selectedSubscriptionPeriod === '1') return 'В месяц'
            if (billingStore.selectedSubscriptionPeriod === '3') return 'Раз в 3 месяца'
            if (billingStore.selectedSubscriptionPeriod === '12') return 'В год'
        })
        const currentTariffPriceDescription = computed(() => {
            if (!currentTariffData.value) return ''
            if (currentTariffData.value.periodInMonths === 1) return 'В месяц'
            if (currentTariffData.value.periodInMonths === 3) return 'Раз в 3 месяца'
            if (currentTariffData.value.periodInMonths === 12) return 'В год'
        })
        const currentTariffName = computed(() =>
            currentTariffData.value ? currentTariffData.value.name : 'Нет тарифа'
        )
        const currentPromoName = computed(() =>
          currentTariffData.value?.promocodeData?.promocode ?
            'Промокод ' + currentTariffData.value?.promocodeData?.promocode + ' -' + currentTariffData.value?.promocodeData?.discountPercent + '%':
            ''
        )
        const selectedPromoName = computed(() =>
          selectedPromoData.value ?
            'Промокод ' + selectedPromoData.value.promocode + ' -' + selectedPromoData.value.discountPercentage + '%':
            ''
        )
        const currentTariffState = computed(() => billingStore.getCurrentTariffState(project.value.id))
        const currentTariffFormattedPrice = computed(() => {
            if (!currentTariffData.value || !currentTariffData.value?.price.toPayPerPeriodWithDiscount) return '0 ₽'
            return billingStore.formatPrice(currentTariffData.value?.price.toPayPerPeriodWithDiscount)
        })
        const currentTariffFormattedPromoPrice = computed(() => {
            return billingStore.formatPrice(currentTariffData.value?.price.regularPay)
        })

        const selectedTariffFormattedPrice = computed(() =>
            billingStore.formatPrice(
                billingStore.getTarrifRegularPrice(
                    selectedTariff.value!.id,
                    billingStore.selectedSubscriptionPeriod!
                )
            )
        )

        const selectedTariffFormattedPromoPrice = computed(() =>
          billingStore.formatPrice(
            billingStore.getTarrifPromoPrice(
              selectedTariff.value!.id,
              billingStore.selectedSubscriptionPeriod!
            )
          )
        )

        const openPaymentModal = () => {
            billingStore.setPaymentModalOpened(true)
        }
        const openProlongModal = () => {
            billingStore.setPaymentModalOpened(true, true)
        }

        const selectedTariffIsSame = computed(
          () => {
              return currentTariffData.value?.periodInMonths &&
              billingStore.selectedTariffId === currentTariffData.value?.integrationId &&
              billingStore.selectedSubscriptionPeriod === currentTariffData.value?.periodInMonths.toString()
          }
        )

        const goToProject = () => {
            //  не используем роутер, так как нам нужна именно перезагрузка страницы
            const projectId = project.value.id
            window.location.replace(`/project/${projectId}`)
        }

        const unsubscribe = () => {
            confirmStore.confirm({
                text: `Вы уверены что хотите отключить подписку?`,
                onConfirm: async () => {
                    unsubscribeLoading.value = true
                    await billingStore.unsubscribeIntegration(project.value.id, currentTariffData.value?.integrationId)
                    goToProject()
                },
            })
        }
        return {
            currentTariffData,
            selectedTariff,
            currentTariffState,
            currentTariffFormattedPrice,
            selectedTariffFormattedPrice,
            selectedTariffFormattedPromoPrice,
            currentTariffFormattedPromoPrice,
            selectedTariffPriceDescription,
            currentTariffPriceDescription,
            currentTariffName,
            selectedPromoName,
            selectedPromoData,
            openPaymentModal,
            openProlongModal,
            currentPromoName,
            selectedTariffIsSame,
            unsubscribe,
            unsubscribeLoading
        }
    },
})
