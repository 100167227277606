<template>
    <div class="auth">
        <div class="mx-0 my-10">
            <h2 class="dashboard">Подключение Data Heroes Dikidi</h2>
        </div>
        <div class="mt-6 instruction_text">
            <h2
                >Шаги для подключения Dikidi описаны в инструкции
                <a @click="removeProject" href="http://help.dataheroes.pro/?slug=1327-podklyuchenie-dikidi-novoe" target="_blank"
                    >по этой ссылке</a
                ></h2
            >
        </div>
        <v-row class="ma-0">
            <div class="btn-wrap">
                <v-btn
                  color="white"
                  class="black--text to-crm-select-btn"
                  @click="$parent.$emit('goBackToCrm')"
                >Назад к выбору CRM</v-btn>
            </div>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import cfg from '../../../../config'
import { createPendingConnectorRecord } from '@root/src/api/pendingConnectors'
import { CONNECTOR_TYPES } from '@root/src/vars/general'
export default {
    name: 'YcAuthSimple',
    components: { TextField, HintIcon },
    data: () => ({
        isAltegio: false,
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
    },
    methods: {
        async login() {
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: 'yClients',
                settings: {
                    isAltegio: this.isAltegio,
                },
            })

            const credentials = {
                isSimpleAuth: true,
                creds: {},
                connector: connector,
            }

            //Дальше логин со стандартными кредсами
            const isLogin = await this.$store.dispatch('yc/login', {
                isSimpleAuth: true,
                creds: {},
                connector: connector,
            })
            if (isLogin) {
                this.$store.commit('project/setLoggedAddingConnector', connector)
                this.$parent.$emit('login')
            }
        },
        async removeProject() {
            await this.$store.dispatch('project/deleteProject', this.$router.currentRoute.params.id)
            await this.$router.push(`/projects`)
        },
    },

    mounted() {},
}
</script>
<style lang="sass" scoped>
img
    max-width: 1016px

h3
    margin-top: 16px
    margin-bottom: 16px
.direction-col
    flex-direction: column
.instruction_text
    display: flex
    flex-direction: column
    gap: 16px
    margin-bottom: 100px

    span
        width: 100%
</style>
