
import * as _ from 'lodash'
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, PropType, ref, watch } from 'vue'
import { Connector } from '@/types/main'
import { MESSAGE_VARS } from '@/vars/GCB2'
import { ManualCommunication } from '@/types/GCB2'
import messages from '@dataheroes/messages'
import CustomActivatorSelect from '@/components/inputs/CustomActivatorSelect.vue'
import { useProject } from '../../hooks/useProject'
import { useUser } from '../../hooks/useUser'

type EditorState = 'view' | 'edit'

export default defineComponent({
    components: { CustomActivatorSelect },
    props: {
        label: { type: String, default: null },
        value: { type: String, required: true },
        readonly: { type: Boolean, default: false },
        placeholder: { type: String, default: 'Введите текст...' },
        fontSize: { type: Number, default: 12 },
        gcb2Connector: { type: Object as PropType<Connector>, default: undefined },
        disableViewState: { type: Boolean, default: false },
        hideViewState: { type: Boolean, default: false },
        hideVarsButton: { type: Boolean, default: false },
        manualCommunication: { type: Object as PropType<ManualCommunication>, default: undefined },
        editorState: { type: String as PropType<EditorState> },
        varsList: { type: Array },
        additionalVars: { type: Array }, // Костыль
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const { project } = useProject()
        const { user } = useUser()
        const editorViewerHeight = ref(0)
        const copyTooltip = ref(false)
        const fallbackEditorState = ref<EditorState>('edit')
        const currentReadonly = computed(() => props.readonly || currentEditorState.value === 'view')
        const messageVarsList = computed(() => {
            return messages.vars.vars
                .filter(el => (!props.varsList ? true : props.varsList.includes(el.id)))
                .map(el => ({ id: _.first(el.aliases), name: _.first(el.aliases) }))
                .concat(props.additionalVars ?? ([] as any))
        })
        const currentEditorState = computed({
            get(): EditorState {
                return props.editorState ? props.editorState : fallbackEditorState.value
            },
            set(value: EditorState): void {
                if (props.editorState) {
                    emit('update:editorState', value)
                } else {
                    fallbackEditorState.value = value
                }
            },
        })
        const processedValue = computed((): string => {
            return messages.vars.substituteVars({
                text: props.value,
                data: {
                    communication: props.manualCommunication,
                    gcb2Connector: props.gcb2Connector,
                    project: project.value,
                    userId: user.value.sub,
                },
            }).text
        })
        const editorText = computed(() =>
            currentEditorState.value === 'edit' ? props.value : processedValue.value
        )
        const copy = () => {
            copyTooltip.value = true
            navigator.clipboard.writeText(editorText.value)
            setTimeout(() => {
                copyTooltip.value = false
            }, 1000)
        }
        const insertVar = (varALias: string) => {
            if (currentReadonly.value) return
            const el = _.get(root.$refs, 'textarea.$refs.input') as any
            const [start, end] = [el.selectionStart, el.selectionEnd]
            emit('input', [props.value.slice(0, start), `{${varALias}}`, props.value.slice(start)].join(''))
        }
        const setEditorState = (state: EditorState) => {
            if (props.readonly) return
            currentEditorState.value = state
        }

        return {
            props,
            emit,
            copy,
            copyTooltip,
            currentReadonly,
            currentEditorState,
            setEditorState,
            editorText,
            processedValue,
            editorViewerHeight,
            insertVar,
            messageVarsList,
        }
    },
})
