<template>
    <div class="auth">
        <div class="mx-0 my-10">
            <h2 class="dashboard">Введите ваши логин и пароль от системы ПрофСалон</h2>
            <h3 class="dashboard"><b>(Важно! Для интеграции необходимо использовать аккаунт с правами владельца)</b></h3>
        </div>
        <div class="form-block pa-10">
            <v-form class="form" v-model="valid">
                <TextField label="Логин ПрофСалон" prependInnerIcon='mdi-account' v-model="credentials.pfLogin" required />
                <TextField
                    prependInnerIcon='mdi-lock'
                    label="Пароль ПрофСалон"
                    v-model="credentials.pfPassword"
                    required
                    type="password"
                />
            </v-form>
            <v-row class="ma-0">
                <div class="btn-wrap">
                    <v-btn
                      color="white"
                      class="black--text to-crm-select-btn"
                      @click="$parent.$emit('goBackToCrm')"
                    >Назад к выбору CRM</v-btn>
                    <v-btn
                      color="main"
                      class="white--text"
                      @click="login"
                      :loading="loading"
                    >Далее</v-btn>
                </div>
            </v-row>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import { login } from '@/api/connectors/altegio'
import cfg from '../../../../config'
import api from '@/api/api'
import { createPendingConnectorRecord, getWebhook } from '@root/src/api/pendingConnectors'
import { CONNECTOR_TYPES } from '@root/src/vars/general'
import { saveCredentials } from '@root/src/api/connectors/profsalonBat'

export default {
    name: 'ProfSalonBatAuth',
    components: { TextField, HintIcon },
    data: () => ({
        valid: false,
        loading: false,
        credentials: {
            pfLogin: '',
            pfPassword: '',
        },
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        btnDisabled() {
            return !this.credentials.pfLogin || !this.credentials.pfPassword
        },
    },
    methods: {
        async login() {
            this.loading = true
            const projectId = this.$router.currentRoute.params.id
            const pendingConnectors = await api.project.getPendingConnectorsByProject({ projectId})
            const pendingConnector = pendingConnectors.data[0];
            const connector = pendingConnector ?? await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: this.currentAddingConnector.type ?? this.currentAddingConnector,
            })

            this.$store.commit('project/setLoggedAddingConnector', connector)

            const { data, error } = await saveCredentials(connector, this.credentials)
            if (!error) {
                if (pendingConnector) {
                    this.$parent.$emit('next')
                } else {
                    this.$parent.$emit('login')
                }
            } else {
                this.$store.dispatch('callNotify', 'Ошибка')
            }
            this.loading = false
        },
    },
    async created() {
        this.webhookData = await getWebhook()
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
.form
    max-width: 400px
.btn-wrap
    margin-top: 12px
    display: flex
    gap: 20px
</style>
