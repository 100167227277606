
import { defineComponent, ref, computed, watch, getCurrentInstance, onMounted, nextTick } from 'vue'
import useRouter from '../../../../../hooks/useRouter'
import * as _ from 'lodash'
import BaseInputOutlined from '../../../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../../../inputs/CustomActivatorSelect.vue'
import { CHANGE_BOT_TRIGGER_TYPES } from '@root/src/vars/GCB2'
import TimeInput from '@root/src/components/inputs/TimeInput.vue'

export default defineComponent({
    name: 'TriggerTypeStep',
    components: { BaseInputOutlined, CustomActivatorSelect, TimeInput },
    props: {
        value: { type: Object, required: true },
        mode: {
            type: String,
            required: true,
        },
        triggerIdFromQuery: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        CHANGE_BOT_TRIGGER_TYPES,
    }),
    setup(props, { emit }) {
        const root = getCurrentInstance().proxy
        const { route } = useRouter(root)
        const project = computed(() => root.$store.getters.projectById(route.value.params.id))
        const triggerTypeList = ref([
            { id: 'recordCreated', name: 'Новая запись' },
            {
                id: 'recordCreatedNew',
                name: 'Новая запись для клиентов, которые никогда не были в салоне',
            },
            {
                id: 'recordCreatedPrev',
                name: 'Новая запись для клиентов, у которых был хотя бы 1 визит',
            },
            { id: 'staffChanged', name: 'Изменение записи' },
            { id: 'timeChanged', name: 'Перенос времени записи' },
            { id: 'clientCome', name: 'Клиент пришёл' },
            { id: 'recordDeleted', name: 'Отмена записи' },
            { id: 'recordConfirmed', name: 'Подтверждение записи' },
            { id: 'clientComeAndPaid', name: 'Запрос оценки визита' },
            { id: 'notificationBefore1Day', name: 'Запрос подтверждения за сутки' },
            { id: 'notificationBefore2Hours', name: 'Напоминание за 2 часа' },
        ])
        const clear = () => {
            props.value.customDelay = '00:00:00'
        }
        watch(
            () => props.value.event,
            v => {
                if (props.triggerIdFromQuery.startsWith('custom_')) {
                    if (v === 'notificationBefore1Day') {
                        if(!props.value.answersList) {
                            props.value.answersList = {
                                '1': ['1', 'ок', 'подтверждаю', 'да', 'хорошо', 'буду'],
                                '2': ['2', 'отмените', 'не приду'],
                            }
                        }                        
                    }
                    if (v === 'clientComeAndPaid') {
                        if(!props.value.answersStatus) {
                            props.value.answersStatus = 'on'
                        }                        
                        if(!props.value.reactions || !props.value.answers || !props.value.answersList) {
                            props.value.reactions = {
                                '1': ['claim', 'message'],
                                '2': ['claim', 'message'],
                                '3': ['claim', 'message'],
                                '4': ['message'],
                                '5': ['message'],
                            }
                            props.value.answers = {
                                '1':
                                    'Очень жаль, что визит вам не понравился. Мы уже сообщили об этом руководству.',
                                '2':
                                    'Очень жаль, что визит вам не понравился. Мы уже сообщили об этом руководству.',
                                '3':
                                    'Очень жаль, что визит вам не понравился. Мы уже сообщили об этом руководству.',
                                '4': 'Очень рады, что вам понравилось!',
                                '5': 'Очень рады, что вам понравилось!',
                            }
                            props.value.answersList = {
                                '1': ['1', '1-', '1+', '0', '0-', '0+', '1!', '0!', '1.'],
                                '2': ['2', '2+', '2-', '2', '2.'],
                                '3': ['3', '3+', '3-', '3', '3.'],
                                '4': ['4', '4+', '4', '4!', '4.'],
                                '5': ['5', '5+', '5-', '5!', '5.', '6', '10', '10!', '555555', '5!!'],
                            }
                        }                       
                    } else {
                        if (!props.value.answersStatus) {
                            props.value.answersStatus = 'off'
                        }                        
                    }
                    if (!v) {
                        emit('validation-updated', false)
                    } else {
                        emit('validation-updated', true)
                    }
                }
            },
            { deep: true, immediate: true }
        )
        const updateTime = (val: number, key: string) => {
            emit('input', Object.assign({}, props.value, { [key]: val }))
        }
        onMounted(() => {})
        return {
            clear,
            triggerTypeList,
            updateTime,
        }
    },
})
