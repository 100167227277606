<template>

    <div class="auth">
        <div class="mx-0">
            <h2>Вам необходимо обратиться в техническую поддержку DentalPro и запросить для нас создание нового пользователя в вашей системе, у которого будут доступы:</h2>
            <h2>1. К API</h2>
            <h2>2. К Созданию запросов к техподдержке</h2>
            <h2>3. К Просмотру истории обращений к техподдержке</h2>

            <h2>После создания заполните поля ниже, указав в них данные нового пользователя</h2>
        </div>
        <div class="form-block pa-10">
            <v-form class="form" v-model="valid">
                <TextField label="Логин" prependInnerIcon='mdi-account' v-model="credentials.login" required />
                <TextField
                  prependInnerIcon='mdi-lock'
                  label="Пароль"
                  v-model="credentials.password"
                  required
                  type="password"
                />
            </v-form>

            <v-row class="ma-0">
                <div class="btn-wrap">
                    <v-btn
                      color="white"
                      class="black--text to-crm-select-btn"
                      @click="$parent.$emit('goBackToCrm')"
                    >Назад к выбору CRM</v-btn>
                    <v-btn
                      color="main"
                      class="white--text"
                      @click="login"
                      :loading="loading"
                      :disabled="btnDisabled"
                    >Далее</v-btn>
                </div>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import cfg from '../../../../config'
import api from '@/api/api'
import { saveCredentials } from '@api/connectors/dentalPro'
import { CONNECTOR_TYPES } from '@/vars/general'
export default {
    components: { TextField, HintIcon },
    data: () => ({
        loading: false,
        validLogin: null,
        valid: false,
        credentials: {
            login: '',
            password: '',
        },
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),

        btnDisabled() {
            if (
                !this.credentials.login ||
                !this.credentials.password
            ) {
                return true
            }
            return false
        },
    },
    methods: {
        async login() {
            this.loading = true

            const projectId = this.$router.currentRoute.params.id
            const pendingConnectors = await api.project.getPendingConnectorsByProject({ projectId})
            const pendingConnector = pendingConnectors.data[0];
            const connector = pendingConnector ?? await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.dentalPro,
            })
            this.$store.commit('project/setLoggedAddingConnector', connector)
            const { data, error } = await saveCredentials(connector, this.credentials)
            if (!error) {
                if (pendingConnector) {
                    this.$parent.$emit('next')
                } else {
                    this.$parent.$emit('login')
                }
            }
            this.loading = false
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: flex-start
    align-items: center
    flex-direction: column
    gap: 12px
    max-width: 1200px
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
.btn-wrap
    margin-top: 12px
    display: flex
    gap: 20px
</style>
